export const flexibleSelectControllerStyle: {
  [key: string]: (
    base: { [key: string]: string },
    state?: { [key: string]: boolean }
  ) => void;
} = {
  placeholder: (base) => ({
    ...base,
    color: '#263142',
    paddingLeft: '7px',
  }),

  control: (base, state) => ({
    ...base,
    border: state?.isFocused
      ? '2px solid #e5e7eb !important'
      : '2px solid #e5e7eb !important',
    boxShadow: state?.isFocused
      ? '0 0 0 2px #cbd5e1 !important'
      : '0 0 0 2px white !important',
    '&:hover': {
      border: state?.isFocused ? 0 : 0,
    },
    backgroundColor: 'white',
    padding: '3px 0',
    margin: '0',
    fontSize: '14px',
  }),

  menuPortal: (base) => ({
    ...base,
    zIndex: 50,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    font: '12px',
    paddingRight: '0',
    color: '#333',
    '&:hover': {
      color: 'black',
    },
    svg: {
      width: 15,
      color: state?.isDisabled ? 'grey' : '#333',
    },
  }),

  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    padding: 0,
    margin: '-2px',
    paddingLeft: '18px',
    backgroundColor: state?.isFocused ? '#368aff' : null,
    color: state?.isDisabled ? 'grey' : state?.isFocused ? 'white' : '#263142',
  }),

  menu: (base) => ({
    ...base,
    margin: '0 1px 0 4px',
    width: '98%',
    border: '1px solid black',
    borderRadius: 0,
    padding: 0,
  }),
};

export const flexibleSelectStyle: {
  [key: string]: (
    base: { [key: string]: string },
    state?: { [key: string]: boolean }
  ) => void;
} = {
  container: (props) => ({
    ...props,
    width: '250px !important',
    fontSize: '15px',
    fontWeight: 200,
  }),

  placeholder: (base) => ({
    ...base,
    fontSize: '15px',
    fontWeight: 200,
    paddingLeft: '7px',
    color: '#263142',
  }),

  control: (base, state) => ({
    ...base,
    border: state?.isFocused
      ? '2px solid #e5e7eb !important'
      : '2px solid #e5e7eb !important',
    boxShadow: state?.isFocused
      ? '0 0 0 2px #cbd5e1 !important'
      : '0 0 0 2px white !important',
    '&:hover': {
      border: state?.isFocused ? 0 : 0,
    },
    backgroundColor: 'white',
    padding: '3px 0',
    margin: '0',
    fontSize: '14px',
  }),

  dropdownIndicator: (base, state) => ({
    ...base,
    font: '12px',
    paddingRight: '0',
    color: '#333',
    '&:hover': {
      color: 'black',
    },
    svg: {
      width: 15,
      color: state?.isDisabled ? 'grey' : '#333',
    },
  }),

  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    fontWeight: 100,
    height: 'auto',
    padding: 0,
    margin: '-2px',
    paddingLeft: '18px',
    backgroundColor: state?.isFocused ? '#368aff' : null,
    color: state?.isDisabled ? 'grey' : state?.isFocused ? 'white' : '#263142',
  }),

  menu: (base) => ({
    ...base,
    margin: '0 1px 0 4px',
    width: '98%',
    border: '1px solid black',
    borderRadius: 0,
    padding: 0,
    overflowY: 'scroll',
    maxHeight: 200,
  }),

  valueContainer: (base) => ({
    ...base,
    height: '35px',
    minHeight: '35px',
    maxHeight: '35px',
  }),
  input: (base) => ({
    ...base,
    margin: 0,
  }),
};
