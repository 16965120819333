import {
  ReactElement,
  forwardRef,
  ForwardRefRenderFunction,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';

// Import components
import Modal, { Props as ModalProps, ModalHandle } from './Modal';
import { Button } from '../Button';
import { ReactComponent as CloseSvg } from 'assets/svg/circle-close-icon.svg';

// Interfaces
export type Props = Omit<ModalProps, 'children' | 'title'>;

type HandleShow = (
  meta: { description: string; title: string },
  onConfirm: () => void
) => void;
export interface ConfirmModalHandle extends Omit<ModalHandle, 'show'> {
  show: HandleShow;
}

const ConfirmModal: ForwardRefRenderFunction<ConfirmModalHandle, Props> = (
  props: Props,
  ref
): ReactElement | null => {
  // Refs
  const modalRef = useRef<ModalHandle>(null);

  // State
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [onConfirm, setOnConfirm] = useState<() => void>();

  const handleShow: HandleShow = ({ description, title }, _onConfirm) => {
    setTitle(title);
    setDescription(description);
    setOnConfirm(() => _onConfirm);
    modalRef.current?.show();
  };

  const handleHide = () => {
    setTitle('');
    setDescription('');
    modalRef.current?.hide();
  };

  const handleOnConfirm = () => {
    onConfirm?.();
    handleHide();
  };

  // Ref interface
  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  return (
    <Modal
      className={`${props.className}`}
      {...props}
      title={title}
      size="small"
      ref={modalRef}
    >
      <div className="flex flex-col items-center justify-center h-full gap-2">
        <CloseSvg style={{ fill: '#fffff' }} color={'#ff0000'} />
        <h2 className="px-16 text-center">{description}</h2>
        <div className="flex gap-2 mt-5">
          <Button
            title="Confirm"
            className="mb-5 shadow"
            color="white"
            round="md"
            size="md"
            onClick={handleOnConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(ConfirmModal);
