/* eslint-disable no-unused-vars */
import { BaseEmployeeType } from './Employee.type';
import { ResponseType } from './Response.type';

// Interfaces
export interface RoleType {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
}

export interface ChangePasswordType {
  currentPassword?: string;
  newPassword: string;
  confirmPassword: string;
  userId?: string;
}

export interface SigninUserRequest {
  email: string;
  password: string;
}
export interface UserRoleType {
  role?: RoleType;
  roleId: string;
  updatedAt?: string;
  userId: string;
  createdAt?: string;
}

export interface BaseUserType {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  employeeId?: string;
  employee?: BaseEmployeeType;
}
export interface UserType extends BaseUserType {
  roles: UserRoleType[] | undefined;
}

export interface RolesType {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
  roles: string[];
}

export interface UserAuthResponseType {
  accessToken?: string;
  user?: UserType;
}

export enum UserRole {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
}

// Types
export type UserNonEmployeeType = Omit<UserType, 'employee'>;
export type UpdateUserType = Omit<UserType, 'id' | 'roles'>;
export type UsersType = ResponseType<UserType>;
export type CreateUserType = Omit<UserType, 'id' | 'roles'>;
