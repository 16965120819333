import { useState, useEffect } from 'react';

import { isFunction } from 'lodash';
import { Control, Controller } from 'react-hook-form';
import ReactSelect, {
  ActionMeta,
  Props as ReactSelectProps,
} from 'react-select';

// Components
import { AbsoluteSpinner } from 'common/components/Spinners';

import {
  flexibleSelectControllerStyle,
  flexibleSelectStyle,
} from './FlexibleSelect.style';

export interface FlexibleSelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

const FlexibleSelect = (props: ReactSelectProps) => {
  // State
  const [selectValue, setSelectedValue] = useState<FlexibleSelectOption | ''>(
    ''
  );

  // Handlers
  const handleOnChange = (
    event: FlexibleSelectOption,
    actionMeta: ActionMeta<unknown>
  ) => {
    const { onChange } = props;
    if (isFunction(onChange)) {
      onChange(event, actionMeta);
    }

    setSelectedValue(event);
  };

  // Effects
  useEffect(() => {
    const options = props.options as FlexibleSelectOption[];
    if (selectValue && options?.length) {
      const optionAvailable = !!options.find(
        (item) => item.label === selectValue.label
      );

      if (!optionAvailable) {
        setSelectedValue('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.options]);

  return (
    <ReactSelect
      {...props}
      value={selectValue}
      options={props.options}
      styles={flexibleSelectStyle}
      onChange={(event, actionMeta) =>
        handleOnChange(
          event as FlexibleSelectOption,
          actionMeta as ActionMeta<unknown>
        )
      }
    />
  );
};

interface FlexibleSelectControllerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  options: FlexibleSelectOption[];
  name: string;
  placeholder: string;
  disabled?: boolean;
  label: string;
  required?: boolean;
  menuPortalTarget?: HTMLBodyElement | HTMLDivElement | HTMLSpanElement | null;
  isLoading?: boolean;
}
export const FlexibleSelectController = ({
  control,
  options,
  name,
  label,
  disabled,
  placeholder,
  required,
  menuPortalTarget = null,
  isLoading = false,
}: FlexibleSelectControllerProps) => {
  if (isLoading) return <AbsoluteSpinner show={isLoading} />;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="flex-row px-1 mt-3">
          <label className="pt-3 text-slate-700">
            {label}:{required ? <span className="text-red-700">*</span> : ''}
          </label>
          <div className="mt-[9px]">
            <ReactSelect
              isDisabled={disabled}
              placeholder={placeholder}
              styles={flexibleSelectControllerStyle}
              value={options.filter((option) => value?.includes(option.value))}
              onChange={(e) => onChange(e?.value)}
              options={options}
              isOptionDisabled={(option) => !!option.disabled}
              components={{
                IndicatorSeparator: () => null,
              }}
              menuPortalTarget={menuPortalTarget}
            />
            {error ? (
              <label className="ml-2 text-red-700">{error.message}</label>
            ) : null}
          </div>
        </div>
      )}
    />
  );
};

export default FlexibleSelect;
