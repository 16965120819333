import {
  IconDefinition,
  faHome,
  faListAlt,
  faUserNinja,
  faUsers,
  faUserFriends,
  faGear,
  faClock,
  faBriefcase,
  faBusinessTime,
  faIdCard,
  faUserCircle,
  faContactBook,
} from '@fortawesome/free-solid-svg-icons';

import routesConfig from './routesConfig';
import { UserRole } from 'common/types/User.type';

export interface ChildrenNavigation {
  title: string;
  to: string;
  icon: IconDefinition;
  end?: boolean;
  roles?: UserRole[];
}

// Interface
export interface MainNavigationItem {
  title: string;
  to: string;
  icon: IconDefinition;
  isLinkItem?: boolean;
  roles: UserRole[];
  subNavItems?: ChildrenNavigation[];
  end?: boolean;
}

export const mainNavigationConfig = (
  isAdmin: boolean
): MainNavigationItem[] => [
  {
    title: 'Dashboard',
    to: routesConfig.dashboard.index.path,
    icon: faHome,
    end: true,
    roles: [UserRole.ADMIN],
  },
  {
    title: 'Users',
    to: routesConfig.users.list.path,
    icon: faUserFriends,
    roles: [UserRole.ADMIN],
  },
  {
    title: 'Employees',
    to: routesConfig.employees.index.basePath!,
    icon: faUsers,
    roles: [UserRole.ADMIN],
    subNavItems: [
      {
        title: 'Employees',
        to: `${routesConfig.employees.index.basePath}${routesConfig.employees.list.path}`,
        icon: faListAlt,
        roles: [UserRole.ADMIN],
      },
      {
        title: 'Skills',
        to: `${routesConfig.employees.index.basePath}${routesConfig.employees.skills.path}`,
        icon: faUserNinja,
        roles: [UserRole.ADMIN],
      },
    ],
  },
  {
    title: 'Clients',
    to: `${routesConfig.workingHours.clients.path}`,
    icon: faIdCard,
    roles: [UserRole.ADMIN],
  },
  {
    title: 'Projects',
    to: `${routesConfig.workingHours.projects.path}`,
    icon: faBriefcase,
    roles: [UserRole.ADMIN],
  },
  {
    title: 'Timesheet',
    to: `${routesConfig.workingHours.timesheet.path}`,
    icon: faClock,
    roles: [UserRole.ADMIN],
  },
  {
    title: 'Reports',
    to: `${routesConfig.workingHours.reports.path}`,
    icon: faBusinessTime,
    roles: [UserRole.ADMIN],
  },
  {
    title: 'Configuration',
    to: routesConfig.configuration.index.path,
    icon: faGear,
    roles: [UserRole.ADMIN],
  },
  {
    title: 'My Profile',
    to: `${routesConfig.myEmployeeProfile.index.path}`,
    icon: faUserCircle,
    roles: [UserRole.EMPLOYEE],
    isLinkItem: true,
  },
  {
    title: 'My projects',
    to: `${routesConfig.employeeRoutes.projects.path}`,
    icon: faBriefcase,
    roles: [UserRole.EMPLOYEE],
  },
  {
    title: 'My reports',
    to: `${routesConfig.employeeRoutes.reports.path}`,
    icon: faBusinessTime,
    roles: [UserRole.EMPLOYEE],
  },
  {
    title: 'My timesheet',
    to: `${routesConfig.employeeRoutes.timesheet.path}`,
    icon: faClock,
    roles: [UserRole.EMPLOYEE],
    isLinkItem: isAdmin,
  },
  {
    title: 'Contacts',
    to: `${routesConfig.contacts.index.path}`,
    icon: faContactBook,
    roles: [UserRole.ADMIN],
    isLinkItem: isAdmin,
  },
];
