import React, { forwardRef } from 'react';
import { get as _get } from 'lodash';

// Styles
import styles from './input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  round?: boolean;
  color?: 'white' | 'blue';
  shadow?: boolean;
  type?: string;
  wrapperClassName?: string;
  className?: string;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  label?: string;
  required?: boolean;
  append?: React.ReactNode;
  disabled?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      type = 'text',
      color = 'white',
      shadow = false,
      round = false,
      className = '',
      wrapperClassName = '',
      label,
      name,
      errors,
      required = false,
      append,
      disabled = false,
      ...props
    },
    ref
  ) => {
    const error = _get(errors, name || '', null);
    return (
      <div className={`text-gray-700 px-1 flex flex-col ${wrapperClassName}`}>
        {label ? (
          <label className="mt-3 text-slate-700">
            {label}:{required ? <span className="text-red-700">*</span> : ''}
          </label>
        ) : null}
        <div
          className={`relative flex justify-center items-center rounded bg-white outline-none border-2 border-[#e2e8f0] placeholder-[#a3b1c3] text-sm font-thin leading-none text-[#263142] mt-2  ${className}`}
        >
          <input
            ref={ref}
            type={type}
            placeholder={placeholder}
            name={name}
            className={`flex-1 h-full  py-3 pl-3 bg-white disabled:opacity-50 focus:outline-none focus:shadow-none ${styles.Input}`}
            disabled={disabled}
            {...props}
          />
          <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full pointer-events-none"></div>
          {append ? (
            <div
              className={`pr-3 ${disabled && 'pointer-events-none opacity-50'}`}
            >
              {append}
            </div>
          ) : null}
        </div>
        {error ? (
          <label className="ml-2 text-red-700 ">{error.message}</label>
        ) : null}
      </div>
    );
  }
);

export default Input;
