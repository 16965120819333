// Import Type
import {
  AccomplishmentType,
  EmployeeCvType,
  EmployeeSkillsStateType,
  EmployeeSkillType,
  EmployeesResponseType,
  EmployeeType,
  EmployeeUpdateType,
} from 'common/types/Employee.type';

import BaseService from './base.service';

class EmployeeService extends BaseService {
  async getEmployees(): Promise<EmployeesResponseType> {
    const res = await this.axios.get('/employees');
    return res.data;
  }

  async createEmployee(employee: EmployeeType): Promise<EmployeeType> {
    const res = await this.axios.post('/employees', employee);
    return res.data;
  }

  async getEmployee(id: string): Promise<EmployeeType> {
    const res = await this.axios.get(`/employees/${id}`);
    return res.data;
  }

  async deleteEmployee(id: string): Promise<EmployeeType> {
    const res = await this.axios.delete(`/employees/${id}`);
    return res.data;
  }
  async updateEmployee(
    id: string,
    data: EmployeeUpdateType
  ): Promise<EmployeeType> {
    const formatEmployeeData = {
      ...data,
      experienceSince: data.experienceSince
        ? new Date(data.experienceSince)
        : null,
      birthday: data.birthday ? new Date(data.birthday) : null,
    };
    const res = await this.axios.patch(`/employees/${id}`, formatEmployeeData);
    return res.data;
  }

  async getAccomplishments(id: string) {
    const res = await this.axios.get(`/employees/${id}/accomplishments`);
    return res.data;
  }

  async createAccomplishment(
    employeeId: string | undefined,
    accomplishment: AccomplishmentType
  ): Promise<AccomplishmentType> {
    const formatAccomplishmentData = {
      ...accomplishment,
      dateFrom: new Date(accomplishment?.dateFrom),
      dateTo: new Date(accomplishment.dateTo ? accomplishment.dateTo : ''),
    };
    const res = await this.axios.post(
      `/employees/${employeeId}/accomplishment`,
      formatAccomplishmentData
    );
    return res.data;
  }
  async updateAccomplishment(
    employeeId: string | undefined,
    accomplishmentId: string,
    data: AccomplishmentType
  ): Promise<AccomplishmentType> {
    const formatAccomplishmentData = {
      ...data,
      dateFrom: new Date(data?.dateFrom),
      dateTo: new Date(data.dateTo ? data.dateTo : ''),
    };
    const res = await this.axios.patch(
      `/employees/${employeeId}/accomplishment/${accomplishmentId}`,
      formatAccomplishmentData
    );
    return res.data;
  }
  async deleteAccomplishment(
    employeeId: string | undefined,
    accomplishmentId: string
  ): Promise<AccomplishmentType> {
    const res = await this.axios.delete(
      `/employees/${employeeId}/accomplishment/${accomplishmentId}`
    );
    return res.data;
  }

  async getEmployeeSkills(employeeId: string) {
    const res = await this.axios.get(`/employees/${employeeId}/skills`);
    return res.data.items;
  }

  async getEmployeeCategorySkills(employeeId: string) {
    const res = await this.axios.get(
      `/employees/${employeeId}/category-skills`
    );
    return res.data;
  }

  async deleteEmployeeSkill(
    employeeId: string,
    skillId: string
  ): Promise<EmployeeSkillType> {
    const res = await this.axios.delete(
      `/employees/${employeeId}/skill/${skillId}`
    );
    return res.data;
  }

  async updateEmployeeSkill(
    employeeId: string,
    skillId: string | null | undefined,
    payload: EmployeeSkillType | undefined
  ) {
    const res = await this.axios.patch(
      `/employees/${employeeId}/skill/${skillId}`,
      payload
    );
    return res.data;
  }
  async createEmployeeSkill(
    employeeId: string | undefined,
    payload: EmployeeSkillType
  ) {
    const res = await this.axios.post(
      `/employees/${employeeId}/skill`,
      payload
    );
    return res.data;
  }

  async createEmployeeCv(payload: EmployeeCvType): Promise<EmployeeCvType> {
    const res = await this.axios.post('/employees/cv', payload);
    return res.data;
  }

  async updateEmployeeCv(
    employeeId: string,
    cvId: string,
    payload: EmployeeCvType
  ): Promise<EmployeeCvType> {
    const res = await this.axios.patch(
      `/employees/${employeeId}/cv/${cvId}`,
      payload
    );
    return res.data;
  }

  async getEmployeeCv(
    employeeId: string,
    cvId: string
  ): Promise<EmployeeCvType> {
    const res = await this.axios.get(`/employees/${employeeId}/cv/${cvId}`);
    return res.data;
  }

  async deleteEmployeeCv(
    employeeId: string,
    cvId: string
  ): Promise<EmployeeCvType> {
    const res = await this.axios.delete(`/employees/${employeeId}/cv/${cvId}`);
    return res.data;
  }

  async uploadImageProfile(employeeId: string, file: File | null | undefined) {
    if (!file) throw Error('No file to be uploaded!');
    const fd = new FormData();
    fd.append('file', file);
    const res = await this.axios.post(`/employees/${employeeId}/avatar`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  }

  async modifiedEmployeeSkills(
    attachData: EmployeeSkillsStateType,
    employeeId: string
  ) {
    const attachSkills: string[] = [];
    Object.keys(attachData).forEach((skill) => {
      attachData[skill].forEach((item) => attachSkills.push(item));
    });

    const res = await this.axios.patch('/employees/skills', {
      attachSkills,
      employeeId,
    });
    return res.data;
  }
}
const employeeService = new EmployeeService();
export default employeeService;
