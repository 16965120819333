/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useRef } from 'react';

import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

// Assets
import avatarIcon from 'assets/image/avatar.jpg';

// Config
import configuration from 'config';

// Utils
import { ApiError } from 'utils/api';
import { formatDate } from 'utils/data.utils';

// Services
import clientService from 'services/client.service';

// Components
import { AbsoluteSpinner } from 'common/components/Spinners';
import { RichTextContent } from 'common/components/RichTextParser';
import { Card } from 'common/components/Card';
import { Alert } from 'common/components/Alert';
import ContactForm, { ContactFormHandle } from 'pages/Contacts/_ContactForm';

const ClientView = () => {
  const contactFormRef = useRef<ContactFormHandle>(null);

  const params = useParams();
  const { clientId } = params;

  const {
    data: clientData,
    isLoading: isGettingClient,
    error: getClientError,
  } = useQuery('getClient', () => clientService.getClient(clientId!));

  const loading = isGettingClient;
  const _error = getClientError as ApiError;

  const error = _error?.message || '';

  return (
    <div className="px-8 py-5 bg-gray-100 rounded-lg shadow-lg">
      <AbsoluteSpinner show={loading} />
      <Alert message={error} />
      {clientData && (
        <div className="flex flex-col gap-6">
          {/* Client Header */}
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-4xl font-bold capitalize">{clientData.name}</h1>
            <div className="mt-2 text-gray-600">
              <RichTextContent htmlContent={clientData.businessDetails.data} />
            </div>
          </div>

          {/* Responsible Contacts for Client */}
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold">
              Responsible contacts for client:
            </h2>
            <ul className="ml-4 overflow-y-auto text-sm text-gray-500 list-disc list-inside max-h-24">
              {clientData.profiles?.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="mt-1 underline cursor-pointer"
                    onClick={() => contactFormRef?.current?.show(item, true)}
                  >
                    {item.name || 'N/A'}
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Projects Section */}
          <div className="bg-white p-6 rounded-lg shadow-md max-h-[600px] overflow-auto">
            <h2 className="mb-6 text-2xl font-bold">Projects</h2>

            {/* Project Cards */}
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {clientData.projects && clientData.projects.length > 0 ? (
                clientData.projects.map((project) => (
                  <Card key={`${project.clientId}_${project.project.id}`}>
                    {/* Project Details */}
                    <div className="flex-grow">
                      <h5 className="text-xl font-semibold">
                        {project.project.name}
                      </h5>
                      <p className="mt-1 text-sm text-gray-500">
                        Created on: {formatDate(project.project.createdAt)}
                      </p>

                      <div className="mt-4">
                        <p className="text-sm font-semibold text-gray-700">
                          Contacts:
                        </p>
                        <ul className="ml-4 overflow-y-auto text-sm text-gray-500 list-disc list-inside max-h-24">
                          {project.project.profiles?.map((item) => {
                            return (
                              <li
                                key={item.id}
                                className="mt-1 underline cursor-pointer"
                                onClick={() =>
                                  contactFormRef?.current?.show(item, true)
                                }
                              >
                                {item.name || 'N/A'}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    {/* Employees Subtitle */}
                    <div className="pt-4 mt-4 border-t">
                      <h4 className="mb-2 text-sm font-semibold text-gray-700">
                        Employees
                      </h4>

                      {/* Employee List */}
                      <div className="flex flex-wrap justify-start gap-4">
                        {project.project.employees &&
                          project.project.employees.map((employee) => (
                            <Link
                              key={`${employee.projectId}_${employee.employee.id}`}
                              to={`/employee/${employee.employee.id}`}
                              className="flex flex-col items-center text-center"
                            >
                              <img
                                className="object-cover w-10 h-10 mb-1 rounded-full"
                                alt={employee.employee.displayName}
                                src={
                                  employee.employee.profileImage?.url
                                    ? `${configuration().basePublicUrl}/${
                                        employee.employee.profileImage.url
                                      }`
                                    : avatarIcon
                                }
                              />
                              <h4 className="text-sm font-medium text-gray-700">
                                {employee.employee.displayName}
                              </h4>
                            </Link>
                          ))}
                      </div>
                    </div>
                  </Card>
                ))
              ) : (
                <div className="text-center text-gray-500 col-span-full">
                  No projects found!
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ContactForm ref={contactFormRef} />
    </div>
  );
};

export default ClientView;
