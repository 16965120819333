import { FC, useContext } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faUserCircle } from '@fortawesome/free-solid-svg-icons';

// Context
import AuthContext from 'context/AuthContext';

// Types
import { UserRole } from 'common/types/User.type';

// Config
import routes from 'config/routesConfig';

// Assets
import logoUrl from 'assets/image/logo.png';

// Config
import {
  ChildrenNavigation,
  mainNavigationConfig,
  MainNavigationItem,
} from 'config/navigationConfig';

// Components
import NavItem from 'common/components/Navigation/NavItem';

const Navigation: FC = () => {
  const { auth, isAdmin, handleLogout } = useContext(AuthContext);
  const navigate = useNavigate();

  const isItemVisible = (
    item: MainNavigationItem | ChildrenNavigation,
    userRoles: UserRole[]
  ): boolean => {
    if (item.roles) {
      return item.roles.some((role) => userRoles.includes(role));
    }

    return true;
  };

  const filterSubNavItems = (
    subNavItems: ChildrenNavigation[] | undefined,
    userRoles: UserRole[]
  ): ChildrenNavigation[] | undefined => {
    if (!subNavItems) {
      return undefined;
    }

    const filteredItems = subNavItems.filter((subItem) =>
      isItemVisible(subItem, userRoles)
    );

    return filteredItems.length > 0 ? filteredItems : undefined;
  };

  const filterNavigationByRole = (
    navigation: MainNavigationItem[],
    userRoles: UserRole[],
    employeeId: string | null
  ): MainNavigationItem[] => {
    return navigation
      .filter((item) => {
        if (item.title === 'My Profile' && !employeeId) {
          return false;
        }
        if (
          isAdmin &&
          (item.title === 'My projects' ||
            item.title === 'My timesheet' ||
            item.title === 'My reports')
        ) {
          return false;
        }

        return isItemVisible(item, userRoles);
      })
      .map((item) => ({
        ...item,
        subNavItems: filterSubNavItems(item.subNavItems, userRoles),
      }));
  };

  const userRoles = auth?.user?.roles?.map((item) => item.role?.name) || [];
  const employeeId = auth?.user?.employee?.id || null;

  const filteredNavigation = filterNavigationByRole(
    mainNavigationConfig(isAdmin),
    userRoles as UserRole[],
    employeeId
  );

  return (
    <div className="bottom-0 z-50 flex flex-col items-start justify-between h-full min-h-screen transition duration-500 ease-in-out bg-gray-800">
      <div>
        <div className="items-center justify-start hidden p-6 space-x-3 xl:flex">
          <Link
            to={`${
              isAdmin
                ? '/'
                : `${routes.employeeRoutes.timesheet.path}/${auth?.user?.employee?.id}`
            }`}
            className="flex items-center text-white"
          >
            <img
              className="w-[180px] h-[40px]"
              src={logoUrl}
              alt="Homesourcing App"
            />
          </Link>
        </div>

        <div className="flex flex-col items-start justify-start w-full gap-3 pb-5 pl-6 mt-6">
          {filteredNavigation.map(
            ({ title, to, icon, subNavItems, end, isLinkItem }) => {
              const finalTo =
                isLinkItem && auth?.user?.employee?.id
                  ? to.replace(':employeeId', auth.user.employee.id)
                  : to;
              return (
                <>
                  <NavItem
                    key={`${to}_${title}`}
                    title={title}
                    to={finalTo}
                    icon={icon}
                    subNavItems={subNavItems}
                    end={end}
                  />
                </>
              );
            }
          )}
        </div>
      </div>
      {/* Footer */}
      <div
        onClick={() => (isAdmin ? handleLogout() : navigate('/my-profile/me'))}
        className="flex flex-col cursor-pointer items-center justify-between w-64 h-auto px-6 py-4 space-y-32 border-t border-gray-600"
      >
        <div className="flex items-center justify-between w-full ">
          <div className="flex items-center justify-center space-x-2">
            <div className="flex flex-col items-start justify-start">
              <p className="overflow-auto text-xs leading-3 text-white truncate cursor-pointer max-w-44">
                {auth?.user?.email}
              </p>
            </div>
          </div>
          <span>
            {isAdmin ? (
              <FontAwesomeIcon
                icon={faSignOut}
                className="w-5 h-5"
                color="white"
              />
            ) : (
              <FontAwesomeIcon
                icon={faUserCircle}
                className="w-5 h-5"
                color="white"
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
