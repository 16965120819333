import { useState, useEffect } from 'react';

import { isFunction } from 'lodash';
import { Control, Controller } from 'react-hook-form';
import ReactSelect, {
  ActionMeta,
  Props as ReactSelectProps,
} from 'react-select';

import {
  multiSelectControllerStyle,
  multiSelectStyle,
} from './MultiSelect.style';

export interface MultiSelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

const MultiSelect = (props: ReactSelectProps) => {
  // State
  const [selectValue, setSelectedValue] = useState<MultiSelectOption | ''>('');

  // Handlers
  const handleOnChange = (
    event: MultiSelectOption,
    actionMeta: ActionMeta<unknown>
  ) => {
    const { onChange } = props;
    if (isFunction(onChange)) {
      onChange(event, actionMeta);
    }

    setSelectedValue(event);
  };

  // Effects
  useEffect(() => {
    const options = props.options as MultiSelectOption[];
    if (selectValue && options?.length) {
      const optionAvailable = !!options.find(
        (item) => item.label === selectValue.label
      );

      if (!optionAvailable) {
        setSelectedValue('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.options]);

  return (
    <ReactSelect
      {...props}
      value={selectValue}
      options={props.options}
      styles={multiSelectStyle}
      onChange={(event, actionMeta) =>
        handleOnChange(
          event as MultiSelectOption,
          actionMeta as ActionMeta<unknown>
        )
      }
    />
  );
};

interface MultiSelectControllerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  options: MultiSelectOption[];
  name: string;
  placeholder: string;
  disabled?: boolean;
  label: string;
  hideDropDownIndicator?: boolean;
  required?: boolean;
  menuPortalTarget?: HTMLBodyElement | HTMLDivElement | HTMLSpanElement | null;
}
export const MultiSelectController = ({
  control,
  options,
  hideDropDownIndicator,
  name,
  label,
  disabled,
  placeholder,
  required,
  menuPortalTarget = null,
}: MultiSelectControllerProps) => {
  const customComponents = hideDropDownIndicator
    ? { DropdownIndicator: null, IndicatorSeparator: null }
    : {};

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // const modifiedValue = value.map((item) => return { label: item.di, value: item})
        return (
          <div className="flex-row px-1 mt-3">
            <label className="pt-3 text-slate-700">
              {label}:{required ? <span className="text-red-700">*</span> : ''}
            </label>
            <div className="mt-[9px]">
              <ReactSelect
                isDisabled={disabled}
                placeholder={placeholder}
                styles={multiSelectControllerStyle}
                value={options.filter((option) =>
                  value?.includes(option.value)
                )}
                onChange={(e) => onChange(e.map((item) => item.value))}
                options={options}
                isOptionDisabled={(option) => !!option.disabled}
                components={customComponents}
                menuPortalTarget={menuPortalTarget}
                isMulti
              />
              {error ? (
                <label className="ml-2 text-red-700">{error.message}</label>
              ) : null}
            </div>
          </div>
        );
      }}
    />
  );
};

export default MultiSelect;
