/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CreateAddTaskType,
  CreateOrUpdateTimesheetType,
} from 'common/types/Timesheet.type';
import BaseService from './base.service';

class TimesheetService extends BaseService {
  async getAllTimesheets(): Promise<any[]> {
    const res = await this.axios.get('/timesheets');
    return res.data;
  }

  async createTimesheet(data: CreateOrUpdateTimesheetType): Promise<any> {
    const res = await this.axios.post('/timesheets', data);
    return res.data;
  }
  async updateTimesheet(
    data: CreateOrUpdateTimesheetType,
    id: string
  ): Promise<any> {
    const res = await this.axios.patch(`/timesheets/${id}`, data);
    return res.data;
  }

  async deleteTimesheet(id: string): Promise<any> {
    const res = await this.axios.delete(`/timesheets/${id}`);
    return res.data;
  }

  async createAddTask(data: CreateAddTaskType): Promise<CreateAddTaskType> {
    const res = await this.axios.post('/timesheet-task', data);
    return res.data;
  }
  async getAllTasks({
    dateFrom,
    dateTo,
  }: {
    dateFrom: Date;
    dateTo: Date;
  }): Promise<any[]> {
    const res = await this.axios.get('/timesheet-task', {
      params: {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString(),
      },
    });
    return res.data;
  }

  async deleteTimesheetTask(id: string): Promise<any> {
    const res = await this.axios.delete(`/timesheet-task/${id}`);
    return res.data;
  }
}
const timeSheetService = new TimesheetService();
export default timeSheetService;
