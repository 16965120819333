/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ReactElement,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  useEffect,
  useContext,
} from 'react';
import { toast } from 'react-toastify';

import { useMutation } from 'react-query';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Types
import {
  CreateHoursType,
  CreateOrUpdateTimesheetType,
} from 'common/types/Timesheet.type';

// Context
import AuthContext from 'context/AuthContext';

// Services
import timesheetService from 'services/timesheet.service';

// Utils
import { ApiError } from 'utils/api';

// Components
import { Alert } from 'common/components/Alert';
import { FormModal, ModalHandle } from 'common/components/Modal';
import { Input } from 'common/components/Form/Input';
import { normalizeDate } from 'utils/data.utils';

// Schema definition
const schema = yup.object().shape({
  hours: yup.string().required('Hours are required'),
  comment: yup.string().required('Comment is required'),
});

// Interfaces
interface Props {
  onRefresh: () => void;
}

export interface TimesheetFormHandle {
  show: (event?: any, disabled?: boolean) => void;
}

const TimesheetForm: ForwardRefRenderFunction<TimesheetFormHandle, Props> = (
  { onRefresh },

  ref
): ReactElement => {
  // Refs
  const modalFormRef = useRef<ModalHandle>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  // State
  const [activeTimesheet, setActiveTimesheet] =
    useState<CreateOrUpdateTimesheetType>({} as CreateOrUpdateTimesheetType);
  const [formDisabled, setFormDisabled] = useState(false);
  const { auth } = useContext(AuthContext);

  const employeeId = auth?.user?.employee?.id;

  const timesheetTitle = activeTimesheet?.id
    ? formDisabled
      ? 'View a timesheet'
      : 'Update a timesheet'
    : 'Add a timesheet';

  // Form
  const {
    reset: resetForm,
    handleSubmit,
    formState: { isDirty },
    setValue,
    register,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: { ...activeTimesheet },
  });

  const {
    mutate: createTimesheet,
    error: createTimesheetError,
    isLoading: isCreatingTimesheet,
  } = useMutation(
    'createTimesheet',
    (data: CreateOrUpdateTimesheetType) =>
      timesheetService.createTimesheet(data),
    {
      onSuccess: (timesheet) => {
        modalFormRef.current?.hide();
        toast.success(
          `${timesheet.hours}h has been successfully created to ${timesheet.projectWorkspace.name} workspace!`
        );
        resetForm();
        onRefresh();
      },
    }
  );

  const {
    mutate: updateTimesheet,
    error: updateTimesheetError,
    isLoading: isUpdatingTimesheet,
  } = useMutation(
    'updateTimesheet',
    (data: CreateOrUpdateTimesheetType) =>
      timesheetService.updateTimesheet(data, activeTimesheet?.id || ''),
    {
      onSuccess: (timesheet) => {
        modalFormRef.current?.hide();
        resetForm();
        onRefresh();
        toast.success(
          `${timesheet.hours}h has been successfully updated to ${timesheet.projectWorkspace.name} workspace!`
        );
      },
    }
  );

  const loading = isCreatingTimesheet || isUpdatingTimesheet;
  const _error =
    (createTimesheetError as ApiError) || (updateTimesheetError as ApiError);
  const error = _error?.message || '';

  // Handlers
  const handlerOnFormShow = (
    timesheet: CreateOrUpdateTimesheetType,
    disabled = false
  ) => {
    if (timesheet?.id) {
      setValue('id', timesheet?.id);
    }
    setActiveTimesheet(timesheet);
    resetForm(timesheet);
    setFormDisabled(disabled);
    modalFormRef.current?.show();
  };

  const handleCreateTimesheet = ({ hours, comment }: CreateHoursType) => {
    const { date, projectWorkspaceId } = activeTimesheet;

    if (!employeeId && !activeTimesheet.employeeId) return;

    const payloadDate = normalizeDate(new Date(date));

    if (activeTimesheet && activeTimesheet.id) {
      return updateTimesheet({
        hours,
        comment,
        employeeId: employeeId || activeTimesheet.employeeId,
        date: payloadDate,
        projectWorkspaceId,
      });
    }

    return createTimesheet({
      hours,
      comment,
      employeeId: employeeId || activeTimesheet.employeeId,
      date: payloadDate,
      projectWorkspaceId,
    });
  };

  const handleOnSubmit = async (data: any) => {
    await handleCreateTimesheet(data);
  };

  // Ref interface
  useImperativeHandle(ref, () => ({
    show: handlerOnFormShow,
  }));

  // Effects;
  useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTimesheet]);

  return (
    <FormModal
      disabled={!isDirty}
      loading={loading}
      ref={modalFormRef}
      onSubmit={() => formSubmitRef.current?.click()}
      title={timesheetTitle}
      onReset={() => resetForm()}
    >
      <>
        <Alert message={error} />
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Input
            className="mt-0"
            required
            placeholder="e.g 8"
            label="Hours"
            color="white"
            {...register('hours')}
          />
          <Input
            className="mt-0"
            required
            label="Comment"
            placeholder="e.g. Comment"
            color="white"
            {...register('comment')}
          />

          <button type="submit" ref={formSubmitRef} className="hidden">
            Submit
          </button>
        </form>
      </>
    </FormModal>
  );
};
export default forwardRef(TimesheetForm);
