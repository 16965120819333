import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode } from 'react';
interface Props {
  className?: string;
  title?: string;
  type?: 'submit' | 'button';
  round?: 'none' | 'sm' | 'md' | 'lg' | 'xl';
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  background?:
    | 'blue'
    | 'white'
    | 'black'
    | 'green'
    | 'none'
    | 'default'
    | 'red';
  color?: 'default' | 'white' | 'black' | 'none';
  children?: ReactNode | string;
  full?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: IconProp | null;
  disabled?: boolean;
}
const ROUND_CLASSES = {
  none: '',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
};
const COLOR_CLASSES = {
  white: 'text-white',
  black: 'text-black',
  default: 'text-white',
  none: '',
};

const BACKGROUND_CLASSES = {
  blue: 'focus:ring-blue-200 bg-blue-500',
  white: 'focus:ring-gray-200 bg-white',
  black: 'focus:ring-gray-200 bg-black',
  green: 'focus:ring-green-200 bg-green-400',
  red: 'focus:ring-red-200 bg-red-500',
  default: 'bg-gray-800',
  none: '',
};
const SIZE_CLASSES = {
  sm: 'py-2 px-4 text-sm',
  md: 'py-3 px-5 text-md font-normal',
  lg: 'py-4 px-6 text-lg font-medium',
  xl: 'py-5 px-7 text-xl font-bold',
  '2xl': 'py-6 px-32 text-2xl.5 font-bold',
};
const Button = ({
  className,
  title,
  type = 'button',
  round = 'md',
  children = null,
  full = false,
  background = 'default',
  color = 'default',
  size = 'sm',
  icon = null,
  ...props
}: Props): ReactElement => {
  return (
    <button
      type={type}
      className={`
        ${full ? 'w-full' : 'w-fit'}
        disabled:opacity-50
        focus:ring-2 font-normal focus:ring-offset-2 leading-none focus:outline-none
        border-0 border-transparent items-center justify-center flex flex-row gap-1
        shadow-lg
        ${SIZE_CLASSES[size]}
        ${ROUND_CLASSES[round]}
        ${BACKGROUND_CLASSES[background]}
        ${COLOR_CLASSES[color]}
        ${className ? className : ''}
      `}
      {...props}
    >
      {icon ? (
        <span className="pr-1">
          <FontAwesomeIcon icon={icon} />
        </span>
      ) : null}
      {title ? <span>{title}</span> : null}
      {children}
    </button>
  );
};
export default Button;
