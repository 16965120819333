import {
  ReactElement,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import { useMutation, useQuery } from 'react-query';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from 'react-toastify';

// Services
import contactService from 'services/contact.service';
import projectService from 'services/project.service';
import clientService from 'services/client.service';

// Types
import { ContactType, CreateContactType } from 'common/types/Contact.type';

// Utils
import { ApiError } from 'utils/api';
import { phoneRegEx } from 'utils/validation.utils';

// Components
import Input from 'common/components/Form/Input/Input';
import { Alert } from 'common/components/Alert';
import { FormModal, ModalHandle } from 'common/components/Modal';
import { TextArea } from 'common/components/Form/TextArea';
import { MultiSelectController } from 'common/components/Form/MultiSelect';
import { SelectOption } from 'common/components/Form/Select';

interface Props {
  onRefresh?: () => void;
}

// Schema definition
const schema = yup.object({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  role: yup.string().required('Role is required field'),
  phone: yup
    .string()
    .matches(phoneRegEx(), 'Phone number is invalid')
    .required('Phone number is required'),
  address: yup.string(),
  description: yup.string(),
  projectIds: yup
    .array()
    .of(yup.string().uuid('Invalid project ID'))
    .notRequired(),
  clientIds: yup
    .array()
    .of(yup.string().uuid('Invalid client ID'))
    .min(1, 'At least one client is required')
    .required('Client is required'),
});

const emptyContact: ContactType = {
  email: '',
  id: '',
  name: '',
  address: '',
  description: '',
  clients: [],
  createdAt: '',
  updatedAt: '',
  projects: [],
};

export interface ContactFormHandle {
  show: (
    contact?: ContactType | undefined,
    disabled?: boolean,
    clientId?: string
  ) => void;
}

const ContactForm: ForwardRefRenderFunction<ContactFormHandle, Props> = (
  { onRefresh },
  ref
): ReactElement => {
  // Refs
  const modalFormRef = useRef<ModalHandle>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  // State
  const [activeContact, setActiveContact] = useState<ContactType>();
  const [formDisabled, setFormDisabled] = useState(false);
  const [projectsOptions, setProjectsOptions] = useState<SelectOption[]>([]);
  const [ignoreProjectsFromClient, setIgnoreProjectsFromClient] =
    useState(false);

  // Form
  const {
    register,
    control,
    reset: resetForm,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm<CreateContactType>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { ...activeContact },
  });

  const clientIds = watch('clientIds');

  // Queries
  const {
    mutate: createContact,
    isLoading: isCreatingContact,
    error: createContactError,
    reset: resetCreate,
  } = useMutation(
    'createContact',
    (contact: CreateContactType) => contactService.createContact(contact),
    {
      onSuccess: (contact) => {
        toast.success(`Contact ${contact.email} has been created!`);
        setActiveContact(contact);
        resetForm(contact);
        onRefresh?.();
        modalFormRef.current?.hide();
      },
    }
  );

  const {
    mutate: updateContact,
    isLoading: isUpdatingContact,
    error: updateContactError,
  } = useMutation(
    'updateContact',
    ({ id, body }: { id: string; body: CreateContactType }) =>
      contactService.updateContact({ id, body }),
    {
      onSuccess: (contact: ContactType) => {
        resetForm(contact);
        toast.success(`Contact ${contact.email} has been updated`);
        onRefresh?.();
        modalFormRef.current?.hide();
      },
    }
  );

  const {
    isLoading: isGettingClients,
    error: getClientsError,
    data: clientsData,
  } = useQuery('getAllClients', () => clientService.getClients());

  const {
    isLoading: isGettingProjects,
    error: getProjects,
    data: projectsData,
  } = useQuery('getAllProjects', () => projectService.getProjects());

  const loading =
    isCreatingContact ||
    isGettingClients ||
    isGettingProjects ||
    isUpdatingContact;

  const _error =
    (createContactError as ApiError) ||
    (getClientsError as ApiError) ||
    (updateContactError as ApiError) ||
    (getProjects as ApiError);

  const error = _error?.message || '';

  const clientOptions = clientsData?.map(
    ({ id, name }): { label: string; value: string } => {
      return {
        label: name,
        value: id,
      };
    }
  );

  useEffect(() => {
    const getData = projectsData?.filter((item) => {
      const data = [];

      clientIds?.forEach((clientId: string) => {
        const clients = item.clients?.filter(
          (client) => client.client.id === clientId
        );
        if (clients?.length) {
          data.push(item);
        }
      });

      return data.length > 0;
    });

    const options = getData?.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

    setProjectsOptions(options || []);

    if (ignoreProjectsFromClient) {
      const options = projectsData?.map(({ id, name }) => ({
        label: name,
        value: id,
      }));

      setProjectsOptions(options || []);
    }
  }, [clientIds, projectsData, ignoreProjectsFromClient]);

  // Handlers
  const handlerOnFormShow = (contact = emptyContact, disabled = false) => {
    if (contact?.id) {
      setValue('id', contact.id);
    }

    setActiveContact(contact);
    resetForm(contact);
    resetCreate();

    if (!contact.clients.length && disabled) {
      setIgnoreProjectsFromClient(true);
    }

    if (contact.clients.length) {
      setValue(
        'clientIds',
        contact?.clients?.map((item) => item.id)
      );
    }

    if (contact.projects.length) {
      setValue(
        'projectIds',
        contact.projects.map((item) => item.id)
      );
    }

    setFormDisabled(disabled);
    modalFormRef.current?.show();
  };

  const handleUpdateOrCreateUser = async (data: CreateContactType) => {
    const action = data.id
      ? () => {
          updateContact({ id: data.id || '', body: data });
        }
      : () => createContact(data);
    return action();
  };

  const handleOnSubmit = async (data: CreateContactType) => {
    await handleUpdateOrCreateUser(data);
  };

  useEffect(() => {
    if (_error?.message) {
      toast.error(_error.message, { position: 'top-right' });
    }
  }, [_error]);

  // Ref interface
  useImperativeHandle(ref, () => ({
    show: handlerOnFormShow,
  }));

  return (
    <FormModal
      disabled={!isDirty}
      loading={loading}
      viewModal={formDisabled}
      ref={modalFormRef}
      onSubmit={() => formSubmitRef.current?.click()}
      title={
        activeContact?.id && formDisabled
          ? 'View a contact'
          : activeContact?.id
          ? 'Update a contact'
          : 'Add a contact'
      }
      onReset={() => resetForm()}
    >
      <>
        <Alert message={error} />
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Input
            required
            placeholder="e.g. John Doe"
            label="Name"
            shadow={true}
            color="white"
            round={true}
            errors={errors}
            {...register('name')}
            disabled={formDisabled}
          />
          <Input
            required
            placeholder="e.g. john.doe@example.com"
            label="Email"
            shadow={true}
            color="white"
            round={true}
            errors={errors}
            {...register('email')}
            disabled={formDisabled}
          />
          <Input
            required
            placeholder="+47815XXXXX"
            label="Phone number"
            shadow={true}
            color="white"
            round={true}
            errors={errors}
            {...register('phone')}
            disabled={formDisabled}
          />
          <Input
            placeholder="e.g. Street"
            label="Address"
            shadow={true}
            color="white"
            round={true}
            errors={errors}
            {...register('address')}
            disabled={formDisabled}
          />
          <Input
            required
            placeholder="e.g CTO"
            label="Role"
            shadow={true}
            color="white"
            round={true}
            errors={errors}
            {...register('role')}
            disabled={formDisabled}
          />
          <MultiSelectController
            options={clientOptions || []}
            control={control}
            disabled={formDisabled}
            label="Chose clients"
            hideDropDownIndicator
            name="clientIds"
            placeholder="--- Select clients ---"
            required
          />
          <MultiSelectController
            options={projectsOptions || []}
            control={control}
            disabled={formDisabled || !clientIds?.length}
            label="Chose projects"
            hideDropDownIndicator
            name="projectIds"
            placeholder="--- Select projects ---"
          />
          <TextArea
            placeholder=""
            {...register('description')}
            disabled={formDisabled}
            label="Description"
          />
          <button type="submit" ref={formSubmitRef} className="hidden">
            Submit
          </button>
        </form>
      </>
    </FormModal>
  );
};
export default forwardRef(ContactForm);
