import { lazy, Suspense } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'react-datepicker/dist/react-datepicker.css';

// Utils
import configuration from 'config';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//  Context
import { LayoutContext } from 'context/LayoutContext';
import AuthContext from 'context/AuthContext';

// Routes
import routes from 'config/routesConfig';
import EmployeeRoutes from 'routes/EmployeeRoutes';

// Layout
import AuthLayout from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout';

import { useLayoutHook } from 'context/useLayoutHook';
import useAuthHook from 'context/useAuthHook';

// Services
import { configureAPI } from 'utils/api';

// Types
import { UserRole } from 'common/types/User.type';

// Components
import AbsoluteSpinner from 'common/components/Spinners/AbsoluteSpinner';
import ClientView from 'pages/Clients/_ClientView';
import EmployeeProjects from 'pages/Projects/EmployeeProjects';
import EmployeeTimesheet from 'pages/EmployeeTimesheet/EmployeeTimesheet';
import ProtectedRoute from 'common/components/ProtectedRoutes';

//  Pages
const Login = lazy(() => import('pages/Login/LoginPage'));
const UsersPage = lazy(() => import('pages/User/UsersPage'));
const DashboardPage = lazy(() => import('pages/DashboardPage'));
const EmployeePage = lazy(() => import('pages/Employees/EmployeePage'));
const PageNotFound = lazy(() => import('pages/PageNotFound'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const ConfigurationPage = lazy(() => import('pages/ConfigurationPage'));
const Projects = lazy(() => import('pages/Projects/Projects'));
const Clients = lazy(() => import('pages/Clients/Clients'));
const Reports = lazy(() => import('pages/Reports/Reports'));
const MyReports = lazy(() => import('pages/Reports/MyReports'));
const Timesheet = lazy(() => import('pages/Timesheet/Timesheet'));
const Contacts = lazy(() => import('pages/Contacts'));
const MyProfile = lazy(() => import('pages/MyProfile/index'));

// Declarations
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const App = () => {
  const navigate = useNavigate();
  const layout = useLayoutHook();
  const authHookProps = useAuthHook();
  const { isLoggedIn, loadingAuth, handleLogout, auth } = authHookProps;
  const userRoles = auth?.user?.roles || [];

  if (loadingAuth) {
    return <AbsoluteSpinner show />;
  }

  configureAPI({
    token: auth?.accessToken,
    onSignOut: handleLogout,
    isNotFound: () => navigate('/404page'),
  });

  const renderDevTools =
    configuration().env === 'production' ? null : (
      <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
    );

  return (
    <QueryClientProvider client={queryClient}>
      <LayoutContext.Provider value={layout}>
        <AuthContext.Provider value={authHookProps}>
          {renderDevTools}
          <ToastContainer
            position="top-right"
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          {!isLoggedIn ? (
            <AuthLayout>
              <Suspense fallback={<AbsoluteSpinner show />}>
                <Routes>
                  <Route path={routes.auth.login.path} element={<Login />} />
                  <Route
                    path={routes.auth.resetPassword.path}
                    element={<ResetPassword />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={routes.auth.login.path} replace />}
                  />
                </Routes>
              </Suspense>
            </AuthLayout>
          ) : (
            <MainLayout>
              <Suspense fallback={<AbsoluteSpinner show />}>
                <Routes>
                  <Route
                    path={routes.dashboard.index.path}
                    element={
                      <ProtectedRoute
                        allowedRoleIds={[UserRole.ADMIN]}
                        userRoles={userRoles}
                      >
                        <DashboardPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routes.users.list.path}
                    element={
                      <ProtectedRoute
                        allowedRoleIds={[UserRole.ADMIN]}
                        userRoles={userRoles}
                      >
                        <UsersPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routes.configuration.index.path}
                    element={<ConfigurationPage />}
                  />
                  <Route
                    path={routes.employees.index.path}
                    element={<EmployeeRoutes />}
                  />

                  {[
                    routes.employees.listEmployee.path,
                    routes.employees.employeeCv.path,
                  ].map((path, index) => (
                    <Route
                      key={`${path}_${index}`}
                      path={path}
                      element={<EmployeePage />}
                    />
                  ))}

                  <Route
                    path={routes.workingHours.timesheet.path}
                    element={
                      <ProtectedRoute
                        allowedRoleIds={[UserRole.ADMIN]}
                        userRoles={userRoles}
                      >
                        <Timesheet />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routes.workingHours.projects.path}
                    element={
                      <ProtectedRoute
                        allowedRoleIds={[UserRole.ADMIN]}
                        userRoles={userRoles}
                      >
                        <Projects />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routes.workingHours.clients.path}
                    element={
                      <ProtectedRoute
                        allowedRoleIds={[UserRole.ADMIN]}
                        userRoles={userRoles}
                      >
                        <Clients />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routes.workingHours.listClient.path}
                    element={
                      <ProtectedRoute
                        allowedRoleIds={[UserRole.ADMIN]}
                        userRoles={userRoles}
                      >
                        <ClientView />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routes.workingHours.reports.path}
                    element={
                      <ProtectedRoute
                        allowedRoleIds={[UserRole.ADMIN]}
                        userRoles={userRoles}
                      >
                        <Reports />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routes.contacts.index.path}
                    element={
                      <ProtectedRoute
                        allowedRoleIds={[UserRole.ADMIN]}
                        userRoles={userRoles}
                      >
                        <Contacts />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routes.employeeRoutes.reports.path}
                    element={<MyReports />}
                  />
                  <Route
                    path={`${routes.myEmployeeProfile.index.path}`}
                    element={<EmployeePage />}
                  />
                  <Route
                    path={`${routes.users.user.path}`}
                    element={<MyProfile />}
                  />
                  <Route
                    path={`${routes.myProfile.index.path}`}
                    element={<MyProfile />}
                  />
                  <Route
                    path={`${routes.employeeRoutes.projects.path}`}
                    element={<EmployeeProjects />}
                  />
                  <Route
                    path={`${routes.employeeRoutes.timesheet.path}`}
                    element={<EmployeeTimesheet />}
                  />
                  <Route path="/404page" element={<PageNotFound />} />
                </Routes>
              </Suspense>
            </MainLayout>
          )}
        </AuthContext.Provider>
      </LayoutContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
