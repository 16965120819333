import { format, parseISO } from 'date-fns';

const DATE_FORMAT = 'MM/dd/yyyy';
const DATE_INPUT_FORMAT = 'yyyy/MM/dd';

export const formatDate = (
  date: string | Date | undefined,
  formatDate?: string
): string => {
  if (!date) return '';

  let _date: string | Date = date;

  if (typeof date === 'string') {
    _date = parseISO(date);
  }

  return format(_date as Date, formatDate || DATE_FORMAT);
};

export const formatToInputDate = (date: string | Date): string => {
  if (!date) return '';

  return format(new Date(date), DATE_INPUT_FORMAT);
};

export const normalizeDate = (date: Date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};
