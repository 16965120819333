import {
  ReactElement,
  ReactNode,
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useRef,
  useEffect,
} from 'react';

// Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

// Components
import { AbsoluteSpinner } from 'common/components/Spinners';

export interface Props {
  className?: string;
  contentClassName?: string;
  children: ReactElement | ReactNode;
  title: string;
  loading?: boolean;
  onClose?: () => void;
  size?: 'small' | 'medium' | 'large';
}

export type ModalHandle = {
  show: () => void;
  hide: () => void;
  scrollToBottom?: () => void;
};

const Modal: ForwardRefRenderFunction<ModalHandle, Props> = (
  {
    className = '',
    contentClassName = '',
    children,
    title,
    loading = false,
    onClose,
    size = 'medium',
  }: Props,
  ref
): ReactElement | null => {
  const [show, setShow] = useState(false);
  const [initiateHide, setInitiateHide] = useState(false);
  const scrollToBotRef = useRef<HTMLDivElement>(null);

  // Handlers
  const handleShow = () => {
    if (loading) return null;
    setShow(true);
  };

  const handleHide = () => {
    setInitiateHide(true);
  };

  // onHide side effect
  useEffect(() => {
    let timeoutID =
      initiateHide && loading
        ? setTimeout(() => {
            // eslint-disable-next-line no-console
            console.error(
              'Modal.tsx',
              'Loading is taking too much time on modal hide!'
            );
          }, 10000)
        : '';

    const clearTimeoutById = () => {
      if (timeoutID) clearTimeout(timeoutID);
      timeoutID = '';
    };

    if (initiateHide && !loading) {
      setInitiateHide(false);
      setShow(false);
      onClose?.();
      clearTimeoutById();
    }

    return () => {
      clearTimeoutById();
    };
  }, [initiateHide, loading, onClose]);

  const handleScrollToBottom = () => {
    scrollToBotRef.current?.scrollIntoView();
  };

  // Ref interface
  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
    scrollToBottom: handleScrollToBottom,
  }));

  const sizeClass = (() => {
    switch (size) {
      case 'small':
        return 'w-4/12 max-h-[35vh]';
      case 'large':
        return 'w-11/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12 max-h-[90vh] min-h-[85vh]';
      case 'medium':
      default:
        return 'w-11/12 lg:w-8/12 xl:w-6/12 2xl:w-5/12 max-h-[80vh] min-h-[70vh]';
    }
  })();

  // Renderers
  if (!show) return null;

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-[100] h-screen w-screen p-10">
      <div
        className={`
          fixed flex flex-col right-0 bottom-1/2 top-1/2 left-0 z-[100] mx-auto -translate-y-1/2
          transform rounded-2xl bg-white p-4
          lg:p-7 ${sizeClass}
          h-full
          ${className}
        `}
      >
        <AbsoluteSpinner show={loading} />
        <div className="flex justify-between ">
          <div>
            <h1 className="text-lg">{title}</h1>
          </div>
          <div>
            <span onClick={handleHide} className="text-lg cursor-pointer">
              <FontAwesomeIcon icon={faWindowClose} size={'lg'} />
            </span>
          </div>
        </div>
        <hr className="my-5" />
        <div
          className={`overflow-hidden max-h-full h-full relative ${contentClassName}`}
        >
          {children}
          <div className="h-10" ref={scrollToBotRef} />
        </div>
      </div>
      <span
        onClick={handleHide}
        className="absolute top-0 bottom-0 left-0 right-0 h-full w-full bg-black opacity-[.67]"
      />
    </div>
  );
};

export default forwardRef(Modal);
