import React, { useState, useRef, useEffect, useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourcePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import {
  addWeeks,
  endOfWeek,
  format,
  isAfter,
  startOfWeek,
  subWeeks,
  isToday,
  addHours,
  isSameDay,
  isThisWeek,
  differenceInCalendarWeeks,
  isFuture,
} from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMutation, useQuery } from 'react-query';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { EventContentArg, SlotLabelContentArg } from '@fullcalendar/core';
import { useParams } from 'react-router-dom';

// Context
import AuthContext from 'context/AuthContext';

// Types
import {
  TimesheetTaskType,
  TimesheetType,
  EventType,
} from 'common/types/Timesheet.type';
import { EmployeeType } from 'common/types/Employee.type';

// Utils
import { ApiError } from 'utils/api';

// Services
import timesheetService from 'services/timesheet.service';
import employeeService from 'services/employee.service';
import meService from 'services/me.service';

// Partials
import EmployeeAddTaskForm, {
  AddTaskFormHandle,
} from 'pages/EmployeeTimesheet/_EmployeeAddTask';
import TimesheetForm, {
  TimesheetFormHandle,
} from 'pages/Timesheet/_TimesheetForm';

// Components
import { AbsoluteSpinner } from 'common/components/Spinners';
import { Alert } from 'common/components/Alert';
import { IconButton } from 'common/components/Button';
import { TableHeader } from 'common/components/Table';
import { ConfirmModal, ConfirmModalHandle } from 'common/components/Modal';

const EmployeeTimesheet: React.FC = () => {
  const calendarRef = useRef<FullCalendar>(null);
  const addTaskFormRef = useRef<AddTaskFormHandle>(null);
  const timesheetFormRef = useRef<TimesheetFormHandle>(null);
  const confirmDeleteModalRef = useRef<ConfirmModalHandle>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const labelContentRef = useRef<any>({});
  const { isAdmin } = useContext(AuthContext);

  const params = useParams();
  const { employeeId } = params;

  const [events, setEvents] = useState<EventType[]>([]);

  const [currentDate, setCurrentDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );

  const calendarApi = calendarRef?.current?.getApi();

  const {
    mutate: deleteTimesheet,
    error: deleteTimesheetError,
    isLoading: isDeletingTimesheet,
  } = useMutation(
    'deleteTimesheet',
    (id: string) => timesheetService.deleteTimesheet(id),
    {
      onSuccess: (timesheet) => {
        toast.success(
          `${timesheet.hours} hours has been successfully deleted from ${timesheet.projectWorkspace.name} workspace`
        );
        refetchTimesheetTasks();
      },
    }
  );
  const {
    isLoading: isGettingEmployees,
    error: getEmployeeError,
    data: employeeData,
  } = useQuery<EmployeeType>(
    'getEmployee',
    () => employeeService.getEmployee(employeeId || ''),
    {
      refetchOnWindowFocus: false,
      enabled: isAdmin,
    }
  );

  const {
    mutate: deleteTimesheetTask,
    error: deleteTimesheetTaskError,
    isLoading: isDeletingTimesheetTask,
  } = useMutation(
    'deleteTimesheetTask',
    (id: string) => timesheetService.deleteTimesheetTask(id),
    {
      onSuccess: (timesheet) => {
        toast.success(
          `${timesheet.projectWorkspace.name} workspace has been successfully deleted along with all its hours.`
        );
        refetchTimesheetTasks();
      },
    }
  );

  const {
    data: timesheetTasksData,
    error: timesheetTasksError,
    isLoading: isGettingTimesheetTasks,
    refetch: refetchTimesheetTasks,
  } = useQuery(
    ['getTimesheetTasks', currentDate, employeeId],
    () =>
      meService.getTimesheet({
        employeeId: employeeId || '',
        dateFrom: startOfWeek(currentDate, { weekStartsOn: 1 }),
        dateTo: endOfWeek(currentDate, { weekStartsOn: 1 }),
      }),
    {
      onSuccess: (timesheetTasks: TimesheetTaskType[]) => {
        const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
        const endOfWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 });

        const filteredEvents: EventType[] = timesheetTasks.flatMap((task) =>
          task.projectWorkspace.timesheets
            .filter(
              (timesheet) =>
                new Date(timesheet.date) >= startOfWeekDate &&
                new Date(timesheet.date) <= endOfWeekDate
            )
            .map((timesheet) => ({
              start: timesheet.date,
              end: addHours(new Date(timesheet.date), 24),
              resourceId: task.projectWorkspaceId,
              title: `${timesheet.hours}`,
              display: timesheet.comment,
              id: timesheet.id,
            }))
        );

        setEvents(filteredEvents);
      },
    }
  );

  const timesheetTasks = timesheetTasksData?.length
    ? timesheetTasksData.map((item) => {
        const timesheets = item.projectWorkspace.timesheets;
        const totalHours = timesheets.reduce(
          (acc: number, timesheet: TimesheetType) => acc + timesheet.hours,
          0
        );
        return {
          id: item.projectWorkspaceId,
          title: item.projectWorkspace.name,
          projectName: item.projectWorkspace.project.name,
          addTaskId: item.id,
          hours: totalHours ? `${totalHours}h` : '',
        };
      })
    : [];

  const title = isAdmin
    ? `${employeeData?.displayName} Timesheet`
    : 'My timesheet';

  const loading =
    isGettingTimesheetTasks ||
    isDeletingTimesheet ||
    isDeletingTimesheetTask ||
    isGettingEmployees;
  const _error =
    (timesheetTasksError as ApiError) ||
    (deleteTimesheetError as ApiError) ||
    (deleteTimesheetTaskError as ApiError) ||
    (getEmployeeError as ApiError);
  const error = _error?.message || '';

  const handleDateClick = (date: Date | string, resourceId: string) => {
    const clickedDate = new Date(date);
    const today = new Date();
    const isEnrolledDay = events?.filter((item) => {
      const isActualDay = isSameDay(
        new Date(item.start),
        new Date(date.toString())
      );
      return item.resourceId === resourceId && isActualDay;
    });

    if (isAfter(clickedDate, today)) {
      toast.error('You cannot open the form for future dates.');
      return;
    }
    if (isEnrolledDay.length > 0) {
      return timesheetFormRef.current?.show({
        date: date,
        projectWorkspaceId: resourceId,
        hours: +isEnrolledDay[0].title,
        employeeId,
        comment: isEnrolledDay[0].display,
        id: isEnrolledDay[0].id,
      });
    }
    const projectWorkspaceId = resourceId;
    timesheetFormRef.current?.show({ date, projectWorkspaceId, employeeId });
  };

  const handleOnRefresh = () => {
    refetchTimesheetTasks();
  };

  const handleEventSums = () => {
    const sums: { [day: string]: number } = {};
    events.forEach((event) => {
      const day = format(new Date(event.start), 'yyyy-MM-dd');
      sums[day] = (sums[day] || 0) + +event.title;
    });

    return sums;
  };

  // Renders
  const renderEventContent = (item: EventContentArg) => {
    const hours = +item.event.title;
    const eventBackGround =
      hours > 16
        ? 'text-red-600'
        : hours > 8
        ? 'text-yellow-600'
        : 'text-black';
    return (
      <div
        className={`p-2 !h-full  flex font-bold gap-1 cursor-pointer ${eventBackGround}`}
      >
        <div className="flex flex-1 gap-1 overflow-hidden truncate flex-nowrap">
          <span>{hours}h</span>-
          <abbr
            title={item.event.display}
            className="flex-1 overflow-hidden no-underline truncate"
          >
            {item.event.display}
          </abbr>
        </div>
        <div>
          <IconButton
            icon={faTrashAlt}
            onClick={(e) => {
              e.stopPropagation();
              confirmDeleteModalRef.current?.show(
                {
                  title: 'Are you sure?',
                  description:
                    'Do you really want to delete these records? This process cannot be undone.',
                },
                () => deleteTimesheet(item.event.id)
              );
            }}
          />
        </div>
      </div>
    );
  };

  const renderSlotLabelContent = (item: SlotLabelContentArg) => {
    const day = format(new Date(item.date), 'yyyy-MM-dd');
    const activeDay = isToday(new Date(item.date));
    return (
      <>
        <p>{item.text}</p>
        {sums[day] ? (
          <div
            className={`font-normal text-sm ${
              activeDay ? 'text-gray-600' : 'text-gray-400'
            }`}
          >
            Total: <b>{sums[day]}h</b>
          </div>
        ) : null}
      </>
    );
  };

  const renderWeekLabel = () => {
    const today = new Date();
    if (isThisWeek(currentDate, { weekStartsOn: 1 })) {
      return 'This Week';
    } else if (
      differenceInCalendarWeeks(today, currentDate, { weekStartsOn: 1 }) === 1
    ) {
      return 'Last Week';
    } else {
      return `${format(currentDate, 'MMMM d')} - ${format(
        endOfWeek(currentDate, { weekStartsOn: 1 }),
        'MMMM d'
      )}`;
    }
  };

  const sums = handleEventSums();

  useEffect(() => {
    if (events.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      labelContentRef.current.slotLabelContent = (e: any) => {
        if (!events.length) return null;
        const day = format(new Date(e.date), 'yyyy-MM-dd');
        const activeDay = isToday(new Date(e.date));
        return (
          <div>
            <div>{e.text}</div>
            {sums[day] ? (
              <div
                className={`font-normal text-sm ${
                  activeDay ? 'text-gray-200' : 'text-gray-600'
                }`}
              >
                - Total: {sums[day]}h
              </div>
            ) : null}
          </div>
        );
      };
    } else {
      labelContentRef.current = {};
    }
  }, [events, sums]);

  useEffect(() => {
    setEvents([]);
    refetchTimesheetTasks();
  }, [currentDate, refetchTimesheetTasks]);

  useEffect(() => {
    if (calendarApi) {
      calendarApi.gotoDate(currentDate);
    }
  }, [currentDate, calendarApi, refetchTimesheetTasks]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      calendarApi?.updateSize();
    });
    return () => {
      window.removeEventListener('resize', () => {
        calendarApi?.updateSize();
      });
    };
  }, [calendarApi]);

  return (
    <div className="bg-white rounded-xl">
      <TableHeader title={title} />
      <div className="tab-buttons">
        <Alert message={error} />
        <AbsoluteSpinner show={loading} />
        <div className="h-full p-4">
          <FullCalendar
            height="auto"
            resourceOrder="title"
            eventDidMount={(info) => {
              info.el.style.maxWidth = 'calc(100% - 20px)';
              info.el.style.left = '0px';
              info.el.style.right = '0px';
            }}
            resourceAreaColumns={[
              {
                field: 'title',
                headerContent: 'Workspaces',
                width: 50,
              },
              {
                field: 'projectName',
                headerContent: 'Project',
                width: 30,
              },
              {
                field: 'hours',
                headerContent: 'Hours',
                width: 20,
                cellClassNames: 'font-bold',
              },
            ]}
            resourceLabelContent={(e) => {
              return (
                <div className="relative flex justify-between">
                  <div className="flex flex-1 gap-1 overflow-hidden truncate flex-nowrap">
                    <abbr
                      title={e.resource.title}
                      className="flex-1 overflow-hidden no-underline truncate"
                    >
                      {e.resource.title}
                    </abbr>
                  </div>
                  <IconButton
                    icon={faTrashAlt}
                    buttonClassName="z-50"
                    onClick={() => {
                      confirmDeleteModalRef.current?.show(
                        {
                          title: 'Are you sure?',
                          description:
                            'Do you really want to delete these records? This process cannot be undone.',
                        },
                        () =>
                          deleteTimesheetTask(
                            e.resource._resource.extendedProps.addTaskId
                          )
                      );
                    }}
                  />
                </div>
              );
            }}
            ref={calendarRef}
            plugins={[resourcePlugin, interactionPlugin]}
            initialView="weekly"
            resources={timesheetTasks}
            slotLabelContent={renderSlotLabelContent}
            dateClick={(e: DateClickArg) =>
              handleDateClick(e.date, e.resource?._resource.id || '')
            }
            events={events}
            eventClick={(e) => {
              const resource = e.event.getResources();
              handleDateClick(
                e.event.start || new Date(),
                resource[0]._resource.id
              );
            }}
            eventClassNames="!bg-white border-none !p-0"
            eventContent={renderEventContent}
            customButtons={{
              next: {
                click:
                  isThisWeek(currentDate, { weekStartsOn: 1 }) ||
                  isFuture(currentDate)
                    ? () => null
                    : (): void => {
                        const date = addWeeks(currentDate, 1);
                        setCurrentDate(date);
                        calendarRef?.current?.getApi()?.gotoDate(date);
                      },
              },
              prev: {
                click: (): void => {
                  const date = subWeeks(currentDate, 1);
                  setCurrentDate(date);
                  calendarRef?.current?.getApi()?.gotoDate(date);
                },
              },
              thisWeek: {
                text: renderWeekLabel(),
                click: (): void => {
                  setCurrentDate(startOfWeek(new Date(), { weekStartsOn: 1 }));
                },
              },
              addTask: {
                text: '+ Add Task',
                click: (): void => addTaskFormRef.current?.show(),
              },
              dateInformation: {
                text: `${format(currentDate, 'MMMM d')} - ${format(
                  endOfWeek(currentDate, { weekStartsOn: 1 }),
                  'MMMM d'
                )}`,
              },
            }}
            headerToolbar={{
              left: 'dateInformation',
              center: 'prev,thisWeek,next',
              right: employeeId ? 'addTask' : '',
            }}
            views={{
              weekly: {
                type: 'resourceTimeline',
                duration: { days: 7 },
                slotDuration: '24:00:00',
                slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
                slotLabelDidMount: (e) => {
                  if (isToday(e.date))
                    e.el.className = '!border-b-2 !border-b-dark-gray';
                },
                dayHeaderClassNames: `${
                  isToday(currentDate) ? 'bg-red-500' : ''
                }`,
              },
            }}
          />
        </div>
        <EmployeeAddTaskForm
          dateFrom={startOfWeek(currentDate, { weekStartsOn: 1 })}
          dateTo={endOfWeek(currentDate, { weekStartsOn: 1 })}
          onRefresh={handleOnRefresh}
          ref={addTaskFormRef}
          resources={timesheetTasks || []}
        />
        <TimesheetForm onRefresh={handleOnRefresh} ref={timesheetFormRef} />
        <ConfirmModal ref={confirmDeleteModalRef} />
      </div>
    </div>
  );
};

export default EmployeeTimesheet;
