import { ReactElement } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BACKGROUND_CLASSES = {
  blue: 'focus:ring-blue-200 bg-blue-500',
  white: 'focus:ring-gray-200 bg-white',
  black: 'focus:ring-gray-200 bg-black',
  green: 'focus:ring-green-200 bg-green-400',
  red: 'focus:ring-red-200 bg-red-500',
  default: 'bg-transparent',
  none: '',
};
const COLOR_CLASSES = {
  blue: 'focus:ring-blue-200 bg-blue-500',
  white: 'focus:ring-gray-200 bg-white',
  black: 'focus:ring-gray-200 bg-black',
  green: 'focus:ring-green-200 bg-green-400',
  red: 'focus:ring-red-200 bg-red-500',
  default: 'text-slate-800',
  none: '',
};
interface Props {
  iconClassName?: string;
  buttonClassName?: string;
  color?: 'blue' | 'white' | 'black' | 'green' | 'none' | 'default' | 'red';
  backgroundColor?:
    | 'blue'
    | 'white'
    | 'black'
    | 'green'
    | 'none'
    | 'default'
    | 'red';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon: IconProp;
  disabled?: boolean;
}

const IconButton = ({
  iconClassName = '',
  buttonClassName = '',
  backgroundColor = 'default',
  color = 'default',
  icon,
  ...props
}: Props): ReactElement => (
  <button
    type="button"
    className={`text-base disabled:opacity-50 ${BACKGROUND_CLASSES[backgroundColor]} ${buttonClassName}`}
    {...props}
  >
    <FontAwesomeIcon
      className={`${iconClassName}`}
      color={COLOR_CLASSES[color]}
      icon={icon}
    />
  </button>
);

export default IconButton;
