/* eslint-disable no-unused-vars */
import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

export enum AlertType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

const AlertColor = {
  [AlertType.SUCCESS]: 'bg-green-400',
  [AlertType.WARNING]: 'bg-yellow-400',
  [AlertType.ERROR]: 'bg-red-400',
  [AlertType.INFO]: 'bg-blue-400',
};

const AlertTitle = {
  [AlertType.SUCCESS]: 'Success',
  [AlertType.WARNING]: 'Warning',
  [AlertType.ERROR]: 'Error',
  [AlertType.INFO]: 'Info',
};

const AlertIcon = {
  [AlertType.SUCCESS]: faCheckCircle,
  [AlertType.WARNING]: faExclamationCircle,
  [AlertType.ERROR]: faTimesCircle,
  [AlertType.INFO]: faInfoCircle,
};

interface AlertProps {
  type?: AlertType;
  message?: string;
}

const Alert = ({
  message = '',
  type = AlertType.ERROR,
}: AlertProps): ReactElement => {
  if (!message) return <></>;
  return (
    <div>
      {/* Code block starts */}
      <div className="my-4 flex items-center justify-center">
        <div className="mx-auto flex w-full flex-col items-center justify-between rounded bg-white py-4 shadow transition duration-150 ease-in-out  md:flex-row  md:py-0">
          <div className="flex flex-col items-center md:flex-row">
            <div
              className={`mr-3 p-4 ${AlertColor[type]}  rounded text-white md:rounded-tr-none md:rounded-br-none`}
            >
              <FontAwesomeIcon icon={AlertIcon[type]} />
            </div>
            <p className="mr-2 mt-2 text-base font-normal text-gray-800 dark:text-gray-800 md:my-0">
              {AlertTitle[type]}
            </p>
            <div className="mr-2 hidden h-1 w-1 rounded-full bg-gray-300  xl:block" />
            <p className="mb-2 text-center text-sm text-gray-800 dark:text-gray-600 sm:mb-0 sm:text-left lg:pt-1 lg:text-base xl:pt-0">
              {message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Alert;
