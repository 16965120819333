import { ClientType, CreateClientType } from 'common/types/Client.type';
import BaseService from './base.service';

class ClientService extends BaseService {
  async getClients(): Promise<ClientType[]> {
    const res = await this.axios.get('/clients');
    return res.data;
  }

  async getClient(clientId: string): Promise<ClientType> {
    const res = await this.axios.get(`/clients/${clientId}`);
    return res.data;
  }

  async createClient(client: CreateClientType) {
    const res = await this.axios.post('/clients', client);
    return res.data;
  }

  async updateClient(client: ClientType) {
    const res = await this.axios.patch(`/clients/${client.id}`, client);
    return res.data;
  }

  async deleteClient(id: string) {
    const res = await this.axios.delete(`/clients/${id}`);
    return res.data;
  }
}
const clientService = new ClientService();
export default clientService;
