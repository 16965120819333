import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
} from 'react';
import { faSave, faRetweet } from '@fortawesome/free-solid-svg-icons';

// Import components
import Modal, { ModalHandle, Props as ModalProps } from './Modal';
import { Button } from 'common/components/Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// Interfaces
export interface ActionType {
  title: string;
  onClick: () => void;
  icon?: IconProp;
  disabled?: boolean;
}
export interface Props extends ModalProps {
  actions?: ActionType[];
  onSubmit?: () => void;
  onCancel?: () => void;
  onReset?: () => void;
  viewModal?: boolean;
  disabled?: boolean;
}
const FormModal: ForwardRefRenderFunction<ModalHandle, Props> = (
  {
    actions = [],
    onSubmit,
    onCancel,
    onReset,
    disabled = false,
    viewModal = false,
    ...props
  }: Props,
  ref
) => {
  // Handlers
  const getDefaultActions = useCallback<() => ActionType[]>(() => {
    const defaultActions: ActionType[] = [];
    if (onSubmit)
      defaultActions.push({
        title: 'Save',
        onClick: onSubmit,
        icon: faSave,
        disabled: disabled,
      });
    if (onReset)
      defaultActions.push({
        title: 'Reset',
        onClick: onReset,
        icon: faRetweet,
        disabled: disabled,
      });
    if (onCancel)
      defaultActions.push({
        title: 'Cancel',
        onClick: onCancel,
        disabled: disabled,
      });
    return defaultActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, onCancel, onReset, disabled]);

  // Rendereres
  const renderActions = (_actions: ActionType[]) =>
    _actions?.map(({ title, onClick, icon, disabled }, index) => (
      <Button
        disabled={disabled}
        round="md"
        key={`${title}_${index}`}
        full
        onClick={onClick}
        icon={icon}
      >
        {title}
      </Button>
    ));

  return (
    <Modal ref={ref} {...props}>
      <div className="relative flex flex-row h-full">
        {/* Content */}
        <div className="w-8/12 flex-1 max-h-[calc(80vh-124px)] h-full   overflow-hidden  overflow-y-auto pr-4">
          <div className="py-1">{props.children}</div>
        </div>

        {/* Sidebar/Actions */}
        <div
          className={`${
            !viewModal
              ? 'max-h-[40vh] h-fit pl-4 flex flex-col overflow-hidden overflow-y-auto gap-2 !w-[200px]'
              : ''
          }`}
        >
          {!viewModal ? renderActions(getDefaultActions()) : null}
          {renderActions(actions)}
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(FormModal);
