import { ReactElement, useRef } from 'react';
import { NavLink } from 'react-router-dom';

// Import styles
import styles from './nav-item.module.scss';

// Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

// Import InterfaceMutableRefObject
import { ChildrenNavigation } from 'config/navigationConfig';

// Types
export interface Props {
  title: string;
  to: string;
  icon: IconDefinition;
  subNavItems?: ChildrenNavigation[];
  end?: boolean;
}

const NavItem = ({
  icon,
  to,
  title,
  subNavItems,
  end = false,
}: Props): ReactElement => {
  const subNavItemsWrapperRef = useRef<HTMLDivElement>(null);

  // Renderers
  const renderSubNavItems = () => {
    if (!subNavItems) return;
    return (
      <div
        style={{
          height: subNavItemsWrapperRef.current?.clientHeight,
        }}
        className={`flex transition-all w-full duration-300 flex-col gap-2 overflow-hidden ${styles.SubNavItemsWrapper}`}
      >
        <div ref={subNavItemsWrapperRef}>
          {subNavItems.map((item) => {
            return (
              <NavLink
                to={item.to}
                key={`item_${item.to}_${item.title}`}
                className={({ isActive }) => {
                  return isActive
                    ? 'text-indigo-400  flex justify-start relative items-center gap-5 py-1 w-full  ml-4 text-sm  divide-y'
                    : 'ml-4 flex justify-start text-white relative items-center gap-5 py-1 w-full   divide-y text-sm';
                }}
                end={item.end}
              >
                <div className="min-w-[23px]">
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                </div>
                {item.title}
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <NavLink
        to={to}
        className={({ isActive }) =>
          isActive
            ? 'text-indigo-400 flex relative items-center  gap-5  py-1 w-full'
            : `text-white flex justify-start relative items-center gap-5 py-1 w-full ${styles.NavItemNotActive}`
        }
        end={end}
      >
        <div className="min-w-[27px]">
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>
        <p className="text-base leading-4 ">{title}</p>
        {subNavItems ? (
          <FontAwesomeIcon
            className={`transition-transform duration-300 transform rotate-0 ${styles.NavItemChevron}`}
            icon={faChevronDown}
          />
        ) : null}
      </NavLink>
      {renderSubNavItems()}
    </div>
  );
};

export default NavItem;
