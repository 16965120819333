import parse from 'react-html-parser';

interface Props {
  htmlContent: string;
}
const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
const boldRegex = /(\b\w+):/g;

const convertToHtml = (content: string) => {
  let _content = content;

  // convert \n to paragraphs
  _content = _content
    .split('\n')
    .map((item) => (item ? `<p>${item}</p>` : ''))
    .join('');

  // Wrap words that end with ":" in bold
  _content = _content.replace(boldRegex, '<b>$1:</b>');

  return _content;
};

export const RichTextContent = ({ htmlContent = '' }: Props) => {
  const isHtml = htmlRegex.test(htmlContent);
  const _htmlContent = isHtml ? htmlContent : convertToHtml(htmlContent);
  return <div className="w-full">{parse(_htmlContent)}</div>;
};
