import { ReactElement } from 'react';
import { get as _get } from 'lodash';

// Import components
import { AbsoluteSpinner } from 'common/components/Spinners';
import styles from './table.module.scss';

export interface Column {
  slug: string;
  title: string;
  component?: ReactElement;
  className?: string;
  key?: string;
  // if clean render set to true render method will not add any parent element
  cleanRender?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (props: any, index: number) => any;
}

export interface Options {
  // which key on table data should be used as rendering key in react
  rowKey: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (props: any) => any;
}

export interface TableProps<T> {
  columns: Column[];
  data: T[];
  options: Options;
  loading?: boolean;
  perPage?: number;
  className?: string;
}

const Table = <T,>({
  columns,
  data,
  options: { rowKey, onRowClick },
  loading = false,
  className = '',
}: TableProps<T>): ReactElement => {
  // Renderers
  const renderColumns = () =>
    columns.map(({ title, slug, className }) => (
      <th
        key={slug}
        className={`font-normal bg-gray-800 text-lg text-left p-2 ${className}`}
      >
        {title}
      </th>
    ));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderRowColumns = (item: T | any, index: number) => {
    return columns.map(
      ({ className, key, render, slug, cleanRender = false }) => {
        const keyData = _get(item, key || '');
        return (
          <td key={slug} className={`px-2 ${className ? className : ''} `}>
            {/* Render key string */}
            {key && !render && (
              <div className="text-md leading-normal text-ellipsis overflow-hidden w-40">
                {keyData}
              </div>
            )}

            {/* Render combine key and render method */}
            {key && render && (
              <div className="text-md leading-normal text-[#17213e] text-ellipsis overflow-hidden w-40">
                {render(keyData, index)}
              </div>
            )}

            {/* Render with render method only */}
            {render &&
              !key &&
              (cleanRender ? (
                render(item, index)
              ) : (
                <div className="text-md leading-normal text-[#17213e] text-ellipsis overflow-hidden w-40">
                  {render(item, index)}
                </div>
              ))}
          </td>
        );
      }
    );
  };

  const renderRows = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data.map((item: any, index): ReactElement => {
      const key = item[rowKey];
      return (
        <tr
          onClick={onRowClick ? () => onRowClick(item) : () => null}
          key={`${key}_${index}`}
          className={`
            h-[70px] text-sm border-b leading-none text-[#17213e] bg-white relative
            transition-all duration-150 transform hover:drop-shadow-xl hover:z-50
            ${onRowClick ? 'cursor-pointer' : ''}
            ${item.className ? item.className : ''}
          `}
        >
          {renderRowColumns(item, index)}
        </tr>
      );
    });
  };

  return (
    <div
      className={`bg-transparent rounded-b-2xl relative overflow-x-auto lg:overflow-x-visible ${className}`}
    >
      <table className={`w-full whitespace-nowrap ${styles.Table}`}>
        <thead>
          <tr className="h-12 w-full text-sm leading-none text-white">
            {renderColumns()}
          </tr>
        </thead>
        <tbody className="relative w-full bg-white">
          {renderRows()}
          {!loading && !data.length && (
            <tr className="h-[70px]">
              <td
                colSpan={columns.length}
                className="w-full text-center py-5 text-gray-600 text-lg"
              >
                There are no data available!
              </td>
            </tr>
          )}
          {loading && (
            <tr className="h-[70px]">
              <td
                colSpan={columns.length}
                className="w-full text-center p-5 text-gray-600 text-lg"
              ></td>
            </tr>
          )}
        </tbody>
      </table>
      <AbsoluteSpinner show={loading} />
    </div>
  );
};

export default Table;
