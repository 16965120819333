import style from './textArea.module.scss';
import { forwardRef, ReactNode } from 'react';
import { get as _get } from 'lodash';

interface TextAreaProps {
  placeholder: string;
  shadow?: boolean;
  className?: string;
  children?: ReactNode;
  rows?: number;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  label?: string;
  required?: boolean;
  append?: React.ReactNode;
  disabled?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      placeholder = '',
      shadow = false,
      className = '',
      rows = 4,
      children,
      label,
      name,
      errors,
      append,
      disabled,
      required = false,
      ...props
    },
    ref
  ) => {
    const error = _get(errors, name || '', null);

    return (
      <div className={`text-gray-700 px-1 flex flex-col ${className}`}>
        {label ? (
          <label className="mt-3 text-slate-700">
            {label}:{required ? <span className="text-red-700">*</span> : ''}
          </label>
        ) : null}
        <div
          className={[
            ' flex bg-white relative disabled:opacity-50 border-2 border-[#e2e8f0] rounded text-sm font-normal leading-none text-[#263142] w-full mt-2',
            shadow ? 'filter drop-shadow-md' : '',
            className,
          ].join(' ')}
        >
          <textarea
            ref={ref}
            placeholder={placeholder}
            rows={rows}
            disabled={disabled}
            className={`${style.textArea} disabled:opacity-50 w-full focus:outline-none py-3 pl-3`}
            name={name}
            {...props}
          >
            {children}
          </textarea>
          <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full pointer-events-none"></div>
          {append ? (
            <div
              className={`pr-3 self-center ${
                disabled ? 'pointer-events-none opacity-50' : ''
              }`}
            >
              {append}
            </div>
          ) : null}
        </div>
        {error ? (
          <label className="mt-2 ml-2 text-red-700">{error?.message}</label>
        ) : null}
      </div>
    );
  }
);

export default TextArea;
