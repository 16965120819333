import React from 'react';

import { Navigate } from 'react-router-dom';

// Types
import { UserRoleType } from 'common/types/User.type';

interface ProtectedRouteProps {
  children: React.ReactNode;
  allowedRoleIds: string[];
  userRoles: UserRoleType[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoleIds,
  userRoles,
}) => {
  const hasAccess = userRoles.some((userRole) =>
    allowedRoleIds.includes(userRole.role?.name || '')
  );

  if (!hasAccess) {
    return <Navigate to="/404page" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
