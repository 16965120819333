// REGEX
export const phoneRegEx = () =>
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const dateFormatRegEx = () =>
  /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/;

export const lettersRegEx = () => /^[a-zA-Z]+$/;
export const lettersWithWhitespaceRegEx = () => /^[a-zA-Z ]+$/;

export const numbersRegEx = () => /^[0-9]+$/;

// Validates
const socialProfilesValidations: { [key: string]: string } = {
  GITHUB: '^(http(s?)://)?(www.)?github.([a-z])+/([A-Za-z0-9]{1,})+/?$',
  LINKEDIN: '(http(s?)://)?(www.)?.linkedin\\.com\\/.*$',
  GITLAB: '^(http(s?)://)?(www.)?gitlab.([a-z])+/([A-Za-z0-9]{1,})+/?$',
  BITBUCKET: '^(http(s?)://)?(www.)?bitbucket.([a-z])+/([A-Za-z0-9]{1,})+/?$',
  TWITTER: 'http(?:s)?://(?:www.)?twitter.com/([a-zA-Z0-9_]+)',
  STACKOVERFLOW:
    '^((https?|ftp|sm  tp)://)?(www.)?[a-z0-9]+.[a-z]+(/[a-zA-Z0-9#]+/?)*$',
  BEHANCE: '(http(s?)://)?(www.)?behance.([a-z])+/([A-Za-z0-9]{1,})',
  DRIBBLE: '(http(s?)://)?(www.)?dribbble.([a-z])+/([A-Za-z0-9]{1,})',
  PINTEREST: '(http(s?)://)?(www.)?pinterest.([a-z])+/([A-Za-z0-9]{1,})',
  WEB: '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
};

export const socialProfileRegEx = (type: string) => {
  const regex = new RegExp(socialProfilesValidations[type]);
  return regex;
};

export const transformEmptyStringToNull = (
  value: string,
  object: string
): string | null => {
  return object === '' ? null : value;
};

export const isFunction = (func: unknown): boolean => {
  return typeof func === 'function';
};
