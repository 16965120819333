import { useQuery } from 'react-query';

// Config
import configuration from 'config';

// Services
import meService from 'services/me.service';

// Utils
import { ApiError } from 'utils/api';
import { formatDate } from 'utils/data.utils';

// Assets
import avatarIcon from 'assets/image/avatar.jpg';

// Components
import { Alert } from 'common/components/Alert';
import { Card } from 'common/components/Card';
import { AbsoluteSpinner } from 'common/components/Spinners';

const EmployeeProjects = () => {
  const {
    isLoading: isGettingProjects,
    error: errorFetchingProjects,
    data: projectData,
  } = useQuery('getEmployeeProjects', () => meService.getProjects());

  const loading = isGettingProjects;
  const _error = errorFetchingProjects as ApiError;

  const error = _error?.message || '';

  return (
    <div>
      <Alert message={error} />
      <AbsoluteSpinner show={loading} />
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-4xl font-bold capitalize">Projects</h1>
      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {projectData?.length && projectData?.length > 0 ? (
          projectData?.map((project) => (
            <Card key={`${project.id}`}>
              <div className="flex-grow">
                <h5 className="text-xl font-semibold">{project.name}</h5>
                <p className="mt-1 text-sm text-gray-500">
                  Created on: {formatDate(project.createdAt)}
                </p>
              </div>
              <div className="pt-4 mt-4 border-t">
                <h4 className="mb-2 text-sm font-semibold text-gray-700">
                  Employees
                </h4>
                <div className="flex flex-wrap justify-start gap-4">
                  {project.employees &&
                    project.employees.map((employee) => (
                      <span
                        key={`${employee.projectId}_${employee.employee.id}`}
                        className="flex flex-col items-center text-center"
                      >
                        <img
                          className="object-cover w-10 h-10 mb-1 rounded-full"
                          alt={employee.employee.displayName}
                          src={
                            employee.employee.profileImage?.url
                              ? `${configuration().basePublicUrl}/${
                                  employee.employee.profileImage.url
                                }`
                              : avatarIcon
                          }
                        />
                        <h4 className="text-sm font-medium text-gray-700">
                          {employee.employee.displayName}
                        </h4>
                      </span>
                    ))}
                </div>
              </div>
            </Card>
          ))
        ) : (
          <div className="text-center text-gray-500 col-span-full">
            No projects found!
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeProjects;
