/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ReactElement,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  useEffect,
  useContext,
} from 'react';

import { useMutation, useQuery } from 'react-query';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

// Types
import { ProjectType } from 'common/types/Project.type';

// Services
import projectService from 'services/project.service';
import timesheetService from 'services/timesheet.service';

// Interfaces
import { CreateAddTaskType, ResourceType } from 'common/types/Timesheet.type';

// Utils
import { ApiError } from 'utils/api';

// Components
import { Alert } from 'common/components/Alert';
import { FormModal, ModalHandle } from 'common/components/Modal';
import { FlexibleSelectController } from 'common/components/Form/FlexibleSelect';
import AuthContext from 'context/AuthContext';
import meService from 'services/me.service';

// Schema definition
const schema = yup.object().shape({
  id: yup.string(),
  projectId: yup.string().required('Project must be chosen'),
  projectWorkspaceId: yup.string().required('Project must be chosen'),
});

// Interfaces
interface Props {
  dateFrom: Date;
  dateTo: Date;
  onRefresh: () => void;
  resources: ResourceType[];
  setAddTask?: React.Dispatch<
    React.SetStateAction<{
      projectName: string;
      workspace: string;
    }>
  >;
}

export interface AddTaskFormHandle {
  show: (project?: any, disabled?: any) => void;
}

const EmployeeAddTaskForm: ForwardRefRenderFunction<
  AddTaskFormHandle,
  Props
> = (
  { onRefresh, dateFrom, dateTo, resources },

  ref
): ReactElement => {
  // Refs
  const modalFormRef = useRef<ModalHandle>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);
  const { isAdmin } = useContext(AuthContext);
  const params = useParams();
  const { employeeId } = params;

  // State
  const [activeAddTask, setActiveAddTask] = useState<any>();
  const [formDisabled, setFormDisabled] = useState(false);

  // Form
  const {
    reset: resetForm,
    handleSubmit,
    formState: { isDirty },
    setValue,
    control,
    watch,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: { ...activeAddTask },
  });

  const projectId = watch('projectId');

  const {
    isLoading: isGettingProjects,
    error: projectsDataError,
    data: projectsData,
  } = useQuery('getProjects', () => {
    if (isAdmin) {
      return projectService.getEmployeeProjects(employeeId || '');
    } else {
      return meService.getProjects();
    }
  });

  const {
    mutate: addTask,
    error: addTaskError,
    isLoading: isCreatingAddTask,
  } = useMutation(
    'addTask',
    (data: CreateAddTaskType) => {
      const payload = data;
      if (!isAdmin) delete payload.employeeId;
      return timesheetService.createAddTask(payload);
    },
    {
      onSuccess: () => {
        toast.success('Task has been successfully created!');
        resetForm();
        onRefresh();
        modalFormRef.current?.hide();
      },
    }
  );

  const loading = isCreatingAddTask || isGettingProjects;
  const _error = (addTaskError as ApiError) || (projectsDataError as ApiError);
  const error = _error?.message || '';

  // Handlers
  const handlerOnFormShow = (project: any, disabled = false) => {
    if (project?.id) {
      setValue('id', project?.id);
    }
    setActiveAddTask(project);
    resetForm(project);
    setFormDisabled(disabled);
    modalFormRef.current?.show();
  };

  const handleCreateTask = ({ ...addTaskData }: any) => {
    const { projectWorkspaceId } = addTaskData;
    addTask({ dateFrom, dateTo, projectWorkspaceId, employeeId });
  };

  const handleOnSubmit = async (data: any) => {
    await handleCreateTask(data);
  };

  const optionsProjects = projectsData?.map(({ name, id }: ProjectType) => {
    return {
      label: name,
      value: id,
    };
  });

  const selectedProject = projectsData?.find(
    (project: ProjectType) => project.id === projectId
  );

  const optionsWorkspaceProject =
    selectedProject?.projectWorkspace?.map((workspace) => {
      return {
        label: workspace.name,
        value: workspace.id,
      };
    }) || [];

  const avoidUsedResources = optionsWorkspaceProject?.filter((option) => {
    return !resources.find(({ id }) => id === option.value);
  });

  // Ref interface
  useImperativeHandle(ref, () => ({
    show: handlerOnFormShow,
  }));

  // Effects;
  useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAddTask]);

  return (
    <FormModal
      disabled={!isDirty}
      loading={loading}
      viewModal={formDisabled}
      ref={modalFormRef}
      onSubmit={() => formSubmitRef.current?.click()}
      title={
        activeAddTask?.id && formDisabled
          ? 'View a timesheet'
          : activeAddTask?.id
          ? 'Update a timesheet'
          : 'Add a task'
      }
      onReset={() => resetForm()}
    >
      <>
        <Alert message={error} />
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <FlexibleSelectController
            options={optionsProjects || []}
            label="Choose a project"
            control={control}
            name="projectId"
            placeholder="--- Select a Project ---"
            // disabled={!!activeProject?.id}
            isLoading={isGettingProjects}
            required
          />
          {projectId && (
            <FlexibleSelectController
              options={avoidUsedResources || []}
              label="Choose a workspace"
              control={control}
              name="projectWorkspaceId"
              placeholder="--- Select a Workspace ---"
              // disabled={!!activeProject?.id}
              // isLoading={isGettingProject}
              required
            />
          )}

          <button type="submit" ref={formSubmitRef} className="hidden">
            Submit
          </button>
        </form>
      </>
    </FormModal>
  );
};
export default forwardRef(EmployeeAddTaskForm);
