import BaseService from './base.service';
import {
  CreateProjectType,
  ProjectType,
  WorkspaceType,
} from 'common/types/Project.type';

class ProjectService extends BaseService {
  async getProjects(): Promise<ProjectType[]> {
    const res = await this.axios.get('/projects');
    return res.data;
  }
  async getProject(projectId: string): Promise<ProjectType> {
    const res = await this.axios.get(`/projects/${projectId}`);
    return res.data;
  }

  async createProject(project: CreateProjectType) {
    const res = await this.axios.post('/projects', project);
    return res.data;
  }

  async updateProject(project: ProjectType) {
    const res = await this.axios.patch(`/projects/${project.id}`, project);
    return res.data;
  }

  async deleteProject(id: string) {
    const res = await this.axios.delete(`/projects/${id}`);
    return res.data;
  }

  async getAllWorkspaces(): Promise<WorkspaceType[]> {
    const res = await this.axios.get('/project-workspaces');
    return res.data;
  }

  async getWorkspaceProject(projectId: string) {
    const res = await this.axios.get(`/project-workspaces/${projectId}`);
    return res.data;
  }

  async getEmployeeProjects(employeeId: string): Promise<ProjectType[]> {
    const res = await this.axios.get(`projects/employeeProjects/${employeeId}`);
    return res.data;
  }

  async createWorkspace(workspace: { name: string; projectId: string }) {
    const res = await this.axios.post('/project-workspaces', workspace);
    return res.data;
  }

  async deleteWorkspace(id: string) {
    const res = await this.axios.delete(`/project-workspaces/${id}`);
    return res.data;
  }
}

const projectService = new ProjectService();
export default projectService;
