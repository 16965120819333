import { ReactElement, ReactNode } from 'react';

interface Props {
  title: string;
  children?: ReactNode;
  childrenClassName?: string;
}

const TableHeader = ({
  title,
  children,
  childrenClassName = '',
}: Props): ReactElement => {
  return (
    <div className="px-4 md:px-10 py-6 w-full bg-white rounded-t-xl">
      <div className="sm:flex items-center justify-between">
        <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
          {title}
        </p>
        <div className={`flex flex-row items-center ${childrenClassName}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
