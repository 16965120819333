// Import Type
import { ContactType, CreateContactType } from 'common/types/Contact.type';

import BaseService from './base.service';

class ContactService extends BaseService {
  async createContact(body: CreateContactType) {
    const res = await this.axios.post('/profiles', body);
    return res.data;
  }

  async getAllContacts(): Promise<ContactType[]> {
    const res = await this.axios.get('/profiles');
    return await res.data;
  }

  async updateContact({
    id,
    body,
  }: {
    id: string;
    body: CreateContactType;
  }): Promise<ContactType> {
    const res = await this.axios.put(`/profiles/${id}`, body);
    return res.data;
  }

  async deleteContact(id: string) {
    const res = await this.axios.delete(`/profiles/${id}`);
    return res.data;
  }
}
const contactService = new ContactService();
export default contactService;
