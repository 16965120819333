import { ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

// Import Components
import { AbsoluteSpinner } from '../Spinners';
import { Button } from '../Button';

// Interface
interface ActionsType {
  title?: string;
  onClick?: () => void;
  element?: ReactNode;
  icon?: IconDefinition;
  disabled?: boolean;
  className?: string;
}
interface CardProps {
  children: ReactNode;
  title?: string;
  actions?: ActionsType[];
  wrapperStyle?: string;
  headerStyle?: string;
  loading?: boolean;
}

const Card = ({
  title,
  actions = [],
  wrapperStyle = '',
  headerStyle = '',
  loading = false,
  children,
}: CardProps) => {
  // Renderers
  const renderActions = () => {
    if (!actions.length) return null;
    return (
      <div className="flex items-center gap-2">
        {actions?.map(
          ({ onClick, element, className, disabled, icon, title }, index) => {
            if (element) return <span key={index}>{element}</span>;
            if (title)
              return (
                <Button
                  key={index}
                  icon={icon}
                  className={className}
                  disabled={disabled}
                  onClick={onClick}
                  title={title}
                />
              );
            return null;
          }
        )}
      </div>
    );
  };
  const renderHeader = () => {
    if (!title && !actions.length) return null;
    return (
      <div
        className={`${headerStyle} text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800  flex justify-between py-2`}
      >
        {title ? <span>{title}</span> : null}
        {renderActions()}
      </div>
    );
  };

  return (
    <div
      className={`${wrapperStyle} px-6 py-3 pb-6 mt-5 bg-white rounded-lg border shadow `}
    >
      <AbsoluteSpinner show={loading} />
      {renderHeader()}
      <div className="h-full">{children}</div>
    </div>
  );
};

export default Card;
