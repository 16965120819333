import BaseService from './base.service';

// Types
import { ProjectType } from 'common/types/Project.type';
import { ReportDataType } from 'common/types/Report.type';
import { TimesheetTaskType } from 'common/types/Timesheet.type';
import { ChangePasswordType, UserType } from 'common/types/User.type';

class MeService extends BaseService {
  async getProfile(): Promise<UserType> {
    const res = await this.axios.get('me/my-profile');
    return res.data;
  }

  async changePassowrd(data: ChangePasswordType) {
    const res = await this.axios.patch('/me/change-password', data);
    return res.data;
  }

  async getProjects(): Promise<ProjectType[]> {
    const res = await this.axios.get('/me/projects');
    return res.data;
  }

  async getReports(): Promise<ReportDataType[]> {
    const res = await this.axios.get('me/reports');
    return await res.data;
  }

  async getTimesheet({
    dateFrom,
    dateTo,
    employeeId,
  }: {
    dateFrom: Date;
    dateTo: Date;
    employeeId: string;
  }): Promise<TimesheetTaskType[]> {
    const res = await this.axios.get(`/me/timesheet/${employeeId}`, {
      params: {
        dateFrom: new Date(dateFrom),
        dateTo: new Date(dateTo),
      },
    });
    return res.data;
  }
}
const meService = new MeService();
export default meService;
