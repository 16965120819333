/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';

// Import Interface
import {
  UserAuthResponseType,
  UserRole,
  UserRoleType,
} from 'common/types/User.type';

const useAuthHook = () => {
  const [auth, setAuth] = useState<UserAuthResponseType>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [errorText, setError] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const handleAuth = () => {
    setLoadingAuth(true);
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    if (auth?.accessToken) {
      setAuth(auth);
      setIsLoggedIn(true);
      setError('');

      const hasAdminRole = auth.user?.roles?.some(
        (item: UserRoleType) => item.role?.name === UserRole.ADMIN
      );
      setIsAdmin(hasAdminRole);
    }
    setLoadingAuth(false);
  };

  const handleLogin = (auth: UserAuthResponseType) => {
    const hasEmployeeRole = auth.user?.roles?.some((item) =>
      item.role?.name.includes(UserRole.EMPLOYEE)
    );
    const hasAdminRole = auth.user?.roles?.some((item) =>
      item.role?.name.includes(UserRole.ADMIN)
    );
    if (hasEmployeeRole && !hasAdminRole && !auth.user?.employee) {
      setError('Contact admin, no employee assigned!');
      setIsLoggedIn(false);
      setAuth(undefined);
      localStorage.clear();
      return;
    }
    setAuth(auth);
    setIsLoggedIn(true);
    localStorage.setItem('auth', JSON.stringify(auth));
    if (hasAdminRole) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  const handleLogout = (error = '') => {
    setError(error);
    setAuth(undefined);
    setIsLoggedIn(false);
    setIsAdmin(false);
    localStorage.clear();
  };

  useEffect(() => {
    handleAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    auth,
    isLoggedIn,
    handleLogin,
    handleLogout,
    isAdmin,
    loadingAuth,
    errorText,
  };
};

export default useAuthHook;
